import { interpolateElement as t } from "../../../language/utils.js";
class s {
  TRANSLATION_KEY = "sr.wechatpay.timetopay";
  LONG_TIMEOUT = 18e4;
  MID_TIMEOUT = 6e4;
  SHORT_TIMEOUT = 3e4;
  srPanel;
  i18n;
  srInterval;
  timeout;
  timeLeft;
  constructor(t) {
    const {
      srPanel: s,
      i18n: e
    } = t;
    this.srPanel = s, this.i18n = e, this.srPanel.setAriaProps({
      "aria-relevant": "additions text"
    });
  }
  update(t) {
    const {
      minutes: s,
      seconds: e
    } = t;
    if ("-" === s || "-" === e) return;
    const i = "string" == typeof s ? parseInt(s, 10) : s,
      r = "string" == typeof e ? parseInt(e, 10) : e;
    this.timeLeft = {
      minutes: i,
      seconds: r
    }, i > 5 && this.timeout !== this.LONG_TIMEOUT && (this.timeout = this.LONG_TIMEOUT, this.setInterval(this.timeout)), i >= 1 && i <= 5 && this.timeout !== this.MID_TIMEOUT && (this.timeout = this.MID_TIMEOUT, this.setInterval(this.timeout)), i < 1 && r > 0 && this.timeout !== this.SHORT_TIMEOUT && (this.timeout = this.SHORT_TIMEOUT, this.setInterval(this.timeout));
  }
  tearDown() {
    this.clearInterval(), this.srPanel.setAriaProps({
      "aria-relevant": this.srPanel.constructor.defaultProps.ariaAttributes["aria-relevant"]
    }), this.srPanel.setMessages(null);
  }
  setInterval(t) {
    this.clearInterval();
    const s = () => {
      this.srPanel.setMessages(null), this.srPanel.setMessages(this.getSrMessages(this.timeLeft));
    };
    s(), this.srInterval = setInterval(s, t);
  }
  getSrMessages({
    minutes: s,
    seconds: e
  }) {
    const i = this.i18n.get(this.TRANSLATION_KEY),
      r = [s, e].map(t => 0 !== t ? s => `${t} ${s}` : () => "");
    return [t(i, r).join("")];
  }
  clearInterval() {
    this.srInterval && clearInterval(this.srInterval);
  }
}
export { s as CountdownA11yReporter };
