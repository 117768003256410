import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Customer, UsersDetailsCustomersListConfigService } from 'gung-standard';
import { OttoOlsenUserDetailsCustomersTableComponent } from '../components/otto-olsen-user-details-customers-table/otto-olsen-user-details-customers-table.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenUserDetailsCustomerListConfigService extends UsersDetailsCustomersListConfigService {
  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenUserDetailsCustomersTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'UserDetailsCustomerTable'
      }
    ];
  }
}
