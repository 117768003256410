import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigFilterGroup,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  PimTemplateProperties,
  SearchField,
  SelectionAction
} from 'gung-list';
import { OttoOlsenBlockOrder, OttoOlsenBlockOrderAgreementsService } from './otto-olsen-block-order-agreements.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { map, Observable } from 'rxjs';
import { OttoOlsenBlockOrderTableListComponent } from '../components/otto-olsen-block-order-table-list/otto-olsen-block-order-table-list.component';
import { MetadataService } from 'gung-standard';

export interface GroupedBlockOrderAgreement {
  agreementId: string;
  customerId: string;
  customerName: string;
  offbeskr: string;
  status: string;
  agreementDate: Date;
  agreementValidToDate: Date;
  contact: string;
  rows: OttoOlsenBlockOrder[];
  orderType: number;
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenBlockOrderAgreementsListConfigService implements ConfigService<GroupedBlockOrderAgreement> {

  customerId: string

  constructor(
    protected ottoOlsenBlockOrderAgreementsService: OttoOlsenBlockOrderAgreementsService, 
    protected metadataService: MetadataService
  ) {}

  getItems(): Observable<GroupedBlockOrderAgreement[]> {
    return this.ottoOlsenBlockOrderAgreementsService.getBlockOrders(this.customerId).pipe(
      map(rows => {
        const groupedData: Map<string, GroupedBlockOrderAgreement> = new Map<string, GroupedBlockOrderAgreement>();

        for (const row of rows) {
          if (!groupedData.has(row.extra.q_gung_ofh?.offnr)) {
            const group = {
              agreementId: row.extra.q_gung_ofh?.offnr,
              customerId: row.customerId,
              customerName: row.extra.q_gung_ofh?.ftgnamn,
              offbeskr: row.extra.q_gung_ofh?.offbeskr,
              agreementDate: new Date(row.extra.q_gung_ofh?.offertdat),
              agreementValidToDate: new Date(row.extra.q_gung_ofh?.giltigtomdatum),
              status: this.metadataService.getMetadataValue('xa7', 'ordstatbeskr',row.extra.q_gung_ofh?.ofh_offstatus),
              contact: row.extra.q_gung_ofh?.kundref2,
              refNumber: row.extra.q_gung_ofh?.kundbestnr,
              orderType: row.extra.q_gung_ofh?.ofh_ordtyp,
              rows: []
            };
            groupedData.set(row.extra.q_gung_ofh?.offnr, group);
          }
          groupedData.get(row.extra.q_gung_ofh?.offnr)?.rows.push(row);
        }
        return [...groupedData.values()];
      })
    );
  }

  getFilters(): ConfigBaseFilter<GroupedBlockOrderAgreement>[] {
    return [];
  }
  getSortOptions(): ListSortOption<GroupedBlockOrderAgreement>[] {
    return [];
  }
  getBatchSizes(): number[] | undefined {
    return [24, 48];
  }
  getLayouts(): ListLayout<GroupedBlockOrderAgreement>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenBlockOrderTableListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'blockorder'
      }
    ];
  }

  getSearchTerms(item: GroupedBlockOrderAgreement): string[] {
    return [item.customerId];
  }

  getItemId(item: GroupedBlockOrderAgreement): string {
    return item.agreementId;
  }
}
