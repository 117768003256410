<div class="container">
  <div class="row border-between">
    <div class="col user-col-min">
      <h5 translate>USER_DETAILS</h5>
      <div class="pt-3">
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input
              gungTranslate="USERNAME"
              [(ngModel)]="currentUser.username"
              [renderStatic]="true"
              [placeholder]="'USERNAME' | translate"
              [disabled]="!isAdmin"
            >
            </lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input gungTranslate="NAME" [(ngModel)]="currentUser.name" [placeholder]="'NAME' | translate" [disabled]="!isAdmin" [renderStatic]="!isAdmin">
            </lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input
              gungTranslate="ASSORTMENT"
              [(ngModel)]="currentUser.assortment"
              [placeholder]="'ASSORTMENT' | translate"
              [disabled]="!isAdmin"
              [renderStatic]="!isAdmin"
            ></lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-password-input
              gungTranslate="PASSWORD"
              [placeholder]="'PASSWORD' | translate"
              [passwordRequirement]="passwordRequirements"
              [(ngModel)]="password"
              (validPassword)="isValid($event)"
              autocomplete="new-password"
            >
            </lib-gung-password-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input gungTranslate="EMAIL" [(ngModel)]="currentUser.email" [placeholder]="'EMAIL' | translate" [disabled]="!isAdmin" [renderStatic]="!isAdmin">
            </lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input gungTranslate="PHONE" [(ngModel)]="currentUser.phone" [placeholder]="'PHONE' | translate" [disabled]="!isAdmin" [renderStatic]="!isAdmin">
            </lib-gung-text-input>
          </div>
        </div>
      </div>
    </div>

    <div class="col user-col-min">
      <h5 translate>ROLES</h5>
      <div class="pt-3 row">
        <div class="col-12">
          <div
            class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
            *ngFor="let role of userRoles" [class.d-none]="role.id === 'ACTUATOR'"
          >
            <label class="form-check-label" [for]="role.id" style="text-transform: unset;" [class.pl-4]="role.id === 'VIEW'">
              {{ role.id | translate | titlecase }}
              <span ngbTooltip="{{ ('USER_ROLE_' + role.id.toUpperCase()) | translate }}">
                <i class="fa-solid fa-circle-info"></i>
              </span>
            </label>
            <input
              type="checkbox"
              class="scale12"
              (change)="(!isAdmin && role.id !== 'VIEW') ? undefined : toogleRole(role.id, role.selected)"
              [id]="role.id"
              [checked]="role.selected"
              [disabled]="(!isAdmin && role.id !== 'VIEW')"
            />
          </div>
        </div>
      </div>

      <h5 translate>GROUPS</h5>
      <div class="row">
        <div class="col-12">
          <h6 class="mt-3">{{ 'EDIT_USER_GROUPS_DESCRIPTION' | translate }}</h6>
        </div>
        <div class="col-12" *ngIf="userGroupsWithoutScanner && userGroupsWithoutScanner.length > 0">
          <div
            class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
            *ngFor="let group of userGroupsWithoutScanner" [class.d-none]="group.key === 'PUBLIC'"
          >
            <label class="form-check-label" [for]="group.key">
              {{ group.key | translate | titlecase }}
            </label>
            <input
              type="checkbox"
              class="scale12"
              (change)="!isAdmin ? undefined : toogleGroup(group.key, group.selected)"
              [id]="group.key"
              [checked]="group.selected"
              [disabled]="!isAdmin"
              [renderStatic]="!isAdmin"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col user-col-min">
      <h5 translate>MULTI_STOCKS</h5>
      <div class="pt-3">
        <div class="row">
          <div class="col-12">
            <div
              class="form-check pt-1 d-flex align-items-center justify-content-between"
              *ngFor="let multiStocksId of multiStocksIds; let i = index; trackBy: trackByFn"
            >
              <label class="form-check-label">
                {{ multiStocksId }}
              </label>
              <button type="button" class="btn btn-link p-1" (click)="!isAdmin ? undefined : remove(i)" [disabled]="!isAdmin"><i class="fas fa-times" [disabled]="!isAdmin"></i></button>
            </div>
          </div>
        </div>

        <div class="row pb-4">
          <div class="col">
            <input type="text" class="form-control form-control-sm" placeholder="0" #value [disabled]="!isAdmin" [readonly]="!isAdmin"/>
          </div>
          <div class="col-6 col-md-3">
            <button type="button" class="btn btn-primary btn-block btn-sm" (click)="!isAdmin ? undefined : add(value)" [disabled]="!isAdmin">
              {{ 'ADD' | translate }}
            </button>
          </div>
        </div>
      </div>
      <hr>
      <div>
        <h5 translate>WAREHOUSES</h5>
        <lib-gung-input-multi-select
            [disabled]="!isAdmin"
            label=""
            [optionsList]="warehouses"
            (selectedValues)="setSelectedWarehouses($event)"
        ></lib-gung-input-multi-select>
      </div>
      <hr>
      <div>
        <h5 translate>SCANNER</h5>


        <div class="row">
            <div class="col-12" *ngIf="userGroupsScannerBoks?.length > 0">
                <h6>Otto Olsen 2-boks</h6>
                <div
                    class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
                    *ngFor="let group of userGroupsScannerBoks" [class.d-none]="group.key === 'PUBLIC'"
                >
                    <label class="form-check-label" [for]="group.key">
                        {{ group.key | translate | titlecase }}
                    </label>
                    <input
                        type="checkbox"
                        class="scale12"
                        (change)="!isAdmin ? undefined : toogleGroup(group.key, group.selected)"
                        [id]="group.key"
                        [checked]="group.selected"
                        [disabled]="!isAdmin"
                        [renderStatic]="!isAdmin"
                    />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" *ngIf="userGroupsScannerKunde?.length > 0">
                <h6>Kundescanner</h6>
                <div
                    class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
                    *ngFor="let group of userGroupsScannerKunde" [class.d-none]="group.key === 'PUBLIC'"
                >
                    <label class="form-check-label" [for]="group.key">
                        {{ group.key | translate | titlecase }}
                    </label>
                    <input
                        type="checkbox"
                        class="scale12"
                        (change)="!isAdmin ? undefined : toogleGroup(group.key, group.selected)"
                        [id]="group.key"
                        [checked]="group.selected"
                        [disabled]="!isAdmin"
                        [renderStatic]="!isAdmin"
                    />
                </div>
            </div>
        </div>

        <div class="pt-1">
          <div class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between">
            <label class="form-check-label" for="disableSetDiscounts" translate>
              SCANNER_SELECT_WAY_OF_DELIVERY
            </label>
            <input
              type="checkbox"
              class="scale12"
              id="disableSetDiscounts"
              [(ngModel)]="currentUser.extra.enableWayOfDeliveryMobileCheckout"
            />
        </div>
      </div>
      <hr>
      <div>
        <h5 translate>BLOCK_ORDERS</h5>
        <div
            class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
          >
            <label class="form-check-label" style="text-transform: unset;" for="block-order">
              {{ 'ACCESS_TO_VIEW' | translate | titlecase }}
            </label>
            <input
              type="checkbox"
              class="scale12"
              [(ngModel)]="currentUser.extra.viewBlockOrders"
              id="block-order"
            />
          </div>
      </div>
    </div>
  </div>
</div>


