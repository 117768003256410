import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { JeevesOrderListConfigService, OrderDateFilter, OrderExpectedDeliveryFilter, OrderStatusFilter } from 'gung-standard-jeeves';
import {
  OrderService,
  SelectedCustomerService,
  MetadataService,
  CustomerService,
  Order,
  AuthService
} from 'gung-standard';
import { first, forkJoin, map, of, switchMap } from 'rxjs';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent, SimpleConfigBaseFilter } from 'gung-list';
import { OttoOlsenOrderCardListComponent } from '../components/otto-olsen-order-card-list/otto-olsen-order-card-list.component';
import { OttoOlsenOrderMobileCardListComponent } from '../components/otto-olsen-order-mobile-card-list/otto-olsen-order-mobile-card-list.component';
import { DateUtilService } from 'gung-common';

export const OrderType: { [id: number]: { label: string, icon: string } } = {
  1: {
    label: 'E-post/Telefon',
    icon: 'fa-solid fa-file-invoice'
  },
  10: {
    label: 'Webshop',
    icon: 'fa-solid fa-cart-shopping'
  },
  15: {
    label: 'Scannerorder',
    icon: 'fa-solid fa-barcode'
  },
  16: {
    label: 'Scannerorder',
    icon: 'fa-solid fa-barcode'
  },
  20: {
    label: 'EDI',
    icon: 'fa-solid fa-arrow-progress'
  },
  21: {
    label: 'EDI',
    icon: 'fa-solid fa-arrow-progress'
  },
  19: {
    label: 'Punchout',
    icon: 'fa-solid fa-cart-arrow-down'
  },
}

export class OrderTypeFilter extends SimpleConfigBaseFilter<Order> {

  getName(): string {
    return 'ORDER_CHANNEL';
  }

  getOptionIds(item: Order): string[] {
    return [item.extra.oh.salestype || 1];
  }

  getOptionName(key: string): string {
    return OrderType[key]?.label || key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenOrderListConfigService extends JeevesOrderListConfigService {
  isScannerOnly = false;

  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    private authService: AuthService,
    protected dateUtilService: DateUtilService
  ) {
    super(translationService, orderService, selectedCustomerService, metadataService, customerService, dateUtilService);
    this.authService
      .getHasRoles('SCANNER')
      .pipe(first())
      .subscribe(isScannerOnly => (this.isScannerOnly = isScannerOnly));
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [
      new OrderDateFilter(),
      new OrderExpectedDeliveryFilter(),
      new OrderStatusFilter(this.metadataService),
      new OrderTypeFilter()
    ];
  }

  getItems() {
    return super.getItems().pipe(
      switchMap(orders => forkJoin({
        orders: of(orders),
        userRoles: this.authService.getRoles().pipe(first())
      })),
      map(({ orders, userRoles }) => {
        return orders.filter(o => this.filterOutOrders(o, userRoles));
      })/*,
      map(orders => {
        return orders.filter(o => o.extra.oh.ordstat !== 90 && o.extra.oh.ordstat !== 0); // Hide Canceled orders
      }),
      map(orders => orders.filter(order => order?.extra?.oh?.salestype !== '1' && order?.extra?.status !== 'ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS'))*/
    );
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        ...super.getLayouts()[0],
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getListItemComponent: () =>
          this.isScannerOnly ? OttoOlsenOrderMobileCardListComponent : OttoOlsenOrderCardListComponent
      }
    ];
  }

  getSearchTerms(item: Order): string[] {
    const terms = super.getSearchTerms(item);

    if (item?.extra?.oh?.kundbestnr) {
      terms.push(item.extra.oh.kundbestnr + '');
    }
    if (item?.extra?.oh?.kundbetalarenr) {
      terms.push(item.extra.oh.kundbetalarenr);
    }
    if (item?.extra?.oh?.kundref2) {
      terms.push(item.extra.oh.kundref2);
    }

    return terms;
  }
  getBatchSizes(): number[] {
    return [144];
  }

  getLimit() {
    return 144;
  }

  getSearchGroupCss() {
    return 'd-none d-sm-none d-md-block';
  }

  protected filterOutOrders(order: Order, userRoles: string[]): boolean {
    const isUser: boolean = userRoles.findIndex(r => r === 'ADMIN') === -1 && userRoles.findIndex(r => r === 'SALES') === -1 && userRoles.findIndex(r => r === 'USER') > -1;
    const ordstat: number = Number(order.extra.oh.ordstat);
    const salestype: number = Number(order.extra.oh.salestype);

    // Filter out canceled orders
    if (ordstat === 90 || ordstat === 0) {
      return false;
    }

    // If is user and salestype betweeen 10 and 29 then show orders with ordstat 10
    if (salestype >= 10 && salestype <= 29 && ordstat === 10) {
      return true;
    }

    if (salestype !== 1 && order.extra.status !== 'ORDER_STATUS_WARNING_ENTRY_IN_PROGRESS') {
      return true;
    }

    return false;
  }
}
