<div class="order-block-agree d-flex justify-content-between">
    <h1>{{'BLOCK_ORDER_AGREEMENTS'| translate}}</h1> 
    @if(isAdmin){
        <a [routerLink]="['/block-order-agreements/create']"  >
            <gung-button
            classes="btn-primary btn"
            label="{{ 'CREATE' | translate }}"
        />
        </a>
    }
    
</div>

<lib-filter-list [configService]="configService"></lib-filter-list>
