<div *ngIf="product; else loading">
  <!-- BREADCRUMB -->
  <div class="row row-breadcrumb" *ngIf="breadcrumbMenu">
    <div class="col-12 mx-5 px-0">
      <!-- <lib-breadcrumb></lib-breadcrumb> <hr/> -->
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb px-0">
          <li
            *ngFor="let breadcrumb of breadcrumbMenu; let last = last"
            class="breadcrumb-item"
            [attr.aria-current]="last ? 'page' : undefined"
          >
            <a [ngClass]="{ 'active-assortment': last }" [routerLink]="breadcrumb.urlParts" translate>{{
              breadcrumb.label
            }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>

  <div class="only-print">
    <div class="spacing-print"></div>
    <div class="row">
      <div class="col-6">
        <h5 class="m-0">{{ product.id }}</h5>
        <b
          *ngIf="
            selectedCustomer &&
            product.extra._customerCrossReferences &&
            product.extra._customerCrossReferences[selectedCustomer.id]
          "
          class="d-block small"
        >
          {{ product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
        </b>
      </div>
      <div class="col-6 text-right">
        <h5 class="m-0">{{ today }}</h5>
      </div>
      <div class="col-12">
        <h1>{{ product | productName }}</h1>
      </div>
      <div class="col-12">
        <h5 class="mt-0 mb-3 small-description">{{ product.extra.ar.artbeskrspec }}</h5>
      </div>
    </div>
  </div>

  <div class="row pt-1 product-detail-data-section">
    <div class="col-xs-12 col-sm-6 no-print">
      <otto-olsen-image-carousel
        *ngIf="product && !(show3dView && modelFile)"
        [openExternal]="true"
        [zoom]="false"
        [images]="productImages"
        [videoUrl]="product.extra?.pim?.videolink"
        [model3dFile]="models3d"
      ></otto-olsen-image-carousel>
    </div>

    <div
      class="col-12 only-print"
      *ngIf="
        (product.extra.images &&
          product.extra.images.length > 0 &&
          product.extra.images[0].s3Uri !== 'images/no-image.jpg' &&
          product.extra.images) ||
          currentAssortment?.extra?.images as images;
        else onlyOneImage
      "
    >
      <div class="row">
        <div class="col" *ngFor="let image of images">
          <img
            class="img-fluid mx-auto d-block"
            src="https://cdn1.gung.io/fit-in/500x500/filters:fill(white):background_color(white):etag({{
              image.s3ETag
            }}):format(jpeg)/{{ image.s3Uri }}"
            alt="{{ image.description }}"
          />
        </div>
      </div>
    </div>
    <ng-template #onlyOneImage>
      <div class="col-xs-12 col-sm-6 only-print">
        <img
          class="img-fluid mx-auto d-block"
          *ngIf="
            (product?.extra?.images && product?.extra?.images?.length > 0 && product.extra.images[0]) ||
              (currentAssortment?.extra?.images &&
                currentAssortment?.extra?.images?.length > 0 &&
                currentAssortment.extra.images[0]) as image;
            else noimage
          "
          src="https://cdn1.gung.io/fit-in/500x500/filters:fill(white):background_color(white):etag({{
            image.s3ETag
          }}):format(jpeg)/{{ image.s3Uri }}"
          alt="{{ image.description }}"
        />
        <ng-template #noimage>
          <img
            class="card-img-top img-fluid w-100"
            src="https://cdn1.gung.io/fit-in/300x300/images/no-image.jpg"
            alt="NO IMAGE"
            class="img-fluid mx-auto d-block"
          />
        </ng-template>
      </div>
    </ng-template>

    <!-- Product data -->
    <div class="col">
      <div class="row no-print">
        <div class="col-10">
          <h5 class="m-0">{{ product.id }}</h5>
          <b
            *ngIf="
              selectedCustomer &&
              product.extra._customerCrossReferences &&
              product.extra._customerCrossReferences[selectedCustomer.id]
            "
            class="d-block small"
          >
            {{ product.extra._customerCrossReferences[selectedCustomer.id].referenceId }}
          </b>
        </div>
        <div class="col-auto ml-auto">
          <button type="button" class="btn btn-link m-0 p-0" onclick="window.print()" [title]="'print' | translate">
            <i class="fa fa-print fa-lg"></i>
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 no-print">
          <h1>
            {{ product | productName }}
            <a
              *ngIf="isProductManager && !pimEditUrlHide"
              class="pim-edit"
              [href]="currentProductPimUrl"
              target="_blank"
              (click)="$event.stopPropagation()"
            >
              <i class="fa-regular fa-pen-to-square"></i>
            </a>
          </h1>
        </div>
        <div class="col-12 no-print">
          <h5 class="mt-0 mb-3 small-description">{{ product.extra.ar.artbeskrspec }}</h5>
        </div>
      </div>
      <div
        class="general-price-info product-price"
        *ngIf="
          !isAnonymous &&
          !(product.extra.pim && product.extra.pim.pricerequest && product.extra.pim.pricerequest !== 'false') &&
          !(product.extra.ar.itemtypecd1 === 0 || product.extra.ar.itemtypecd1 === 7) &&
          (product?.extra?.pim?.activeInB2B || isSales)
        "
      >
        <div class="price-net-gross" *ngIf="!configurableLengths; else configurableLengthsPrice">
          <div class="net-price" [class.blur]="hidePrice">
            <span translate>PRICE</span>:
            {{ price.customerNetPrice.value | number: '1.2-2' : '' : '.' }}
            {{ price.customerNetPrice.currencyCode }}/<!-- {{ product.extra?.ar?.enhetskod }} --><lib-gung-meta-output
              [showOnlyText]="true"
              [value]="product.extra?.ar?.enhetskod"
              metadataTable="xen"
              metaOptionName="enhetskodbeskr"
            ></lib-gung-meta-output
            ><!-- .toLowerCase() -->
            <span *ngIf="Number(product.extra.ar.artlistpris) !== Number(price.backendPriceLevel.price)">
              ({{ 'NETPRICE' | translate }})
            </span>
          </div>
          <div class="gross-price" *ngIf="price.customerDiscountPercent !== 0">
            <span translate>REC_PRICE</span>: {{ price.customerGrossPrice.value | number: '1.2-2' : '' : '.' }}
            {{ price.customerGrossPrice.currencyCode }}/<!-- {{ product.extra?.ar?.enhetskod }} --><lib-gung-meta-output
              [showOnlyText]="true"
              [value]="product.extra?.ar?.enhetskod"
              metadataTable="xen"
              metaOptionName="enhetskodbeskr"
            ></lib-gung-meta-output>
            <span *ngIf="price.customerDiscountPercent !== 0">
              ({{ price.customerDiscountPercent }}% <span translate>DISCOUNT</span>)
            </span>
            <!-- <span class="price-detail" (click)="showPriceDetail=!showPriceDetail" >
              <span translate *ngIf="!showPriceDetail">SEE_PRICE_DETAIL</span>
              <span translate *ngIf="showPriceDetail">HIDE_PRICE_DETAIL</span>
            </span> -->
          </div>
        </div>
        <ng-template #configurableLengthsPrice>
          <h4 *ngIf="configurableLengths?.itemprice; else caluclatePriceLabel" class="product-price">
            <lib-price [price]="configurableLengths.itemprice" [class.blur]="hidePrice"></lib-price> netto / stk
          </h4>
          <ng-template #caluclatePriceLabel>
            <h4 translate>CALC_TO_GET_PRICE</h4>
          </ng-template>
        </ng-template>
        <!-- <div class="price-detail-box">
          <div class="table-responsive">
            <table class="table table-borderless table-sm">
              <tr>
                <th translate>PACKAGING</th>
                <td>
                  <span translate>BOX</span> {{ packageSize }}
                  <!-- {{ product.extra?.ar?.enhetskod }} - ->
                  <lib-gung-meta-output
                    [showOnlyText]="true"
                    [value]="product.extra?.ar?.enhetskod"
                    metadataTable="xen"
                    metaOptionName="enhetskodbeskr"
                  ></lib-gung-meta-output>
                </td>
              </tr>
              <tr>
                <th translate>MIN.PURCHASE</th>
                <td>
                  {{ product?.extra?.ar?.q_salesmoq || product.extra.ar?.q_salesbatchsize || '1,0' }} <lib-gung-meta-output
                    [showOnlyText]="true"
                    [value]="product.extra?.ar?.enhetskod"
                    metadataTable="xen"
                    metaOptionName="enhetskodbeskr"
                  ></lib-gung-meta-output>
                  <!-- {{ product.extra?.ar?.enhetskod }} - ->
                </td>
              </tr>
              <tr *ngIf="cuttingFeePrice">
                <th translate>CUTTING_FEE</th>
                <td>{{ cuttingFeePrice | number : '1.2-2' : '' : '.' }} <span translate>CUTTING_FEE_TEXT</span></td>
              </tr>
            </table>
          </div>
        </div> -->
      </div>

      <!-- <div class="row" *ngIf="!isAnonymous">
        <div class="col-12">
          <i class="fas fa-box"></i>&nbsp;
          <otto-olsen-availability
            [availability]="availability"
            [isSales]="isSales"
            [unit]="product.extra.ar?.enhetskod"
            [details]="true"
            [decimals]="product.extra.ar.antdec"
            [productionDays]="
              product?.extra.ar?.anskaffningssatt &&
              product?.extra.ar?.anskaffningssatt * 1 === 12 &&
              product?.extra.ar?.artgenomloppstid
            "
          ></otto-olsen-availability>
        </div>
      </div> -->

      <!-- <div
        class="row mt-2"
        *ngIf="
          !isAnonymous &&
          !(product.extra.pim && product.extra.pim.pricerequest && product.extra.pim.pricerequest !== 'false') &&
          !(product.extra.ar.itemtypecd1 === 0 || product.extra.ar.itemtypecd1 === 7) &&
          (product?.extra?.pim?.activeInB2B || isSales)
        "
      >
        <div class="col">
          <h4 class="product-price" *ngIf="!configurableLengths; else configurableLengthsPrice">
            <lib-price [price]="price" type="GROSS"></lib-price> netto / {{ product.extra.ar?.enhetskod }}
            <span *ngIf="price.customerDiscountPercent !== 0" [class.blur]="hidePrice">
              (-{{ price.customerDiscountPercent }}%)</span
            >
            <span
              *ngIf="
                price.customerDiscountPercent === 0 &&
                Number(product.extra.ar.artlistpris) !== Number(price.backendPriceLevel.price)
              "
              [class.blur]="hidePrice"
              >({{ 'NET_PRICE' | translate }})</span
            >
          </h4>
          <ng-template #configurableLengthsPrice>
            <h4 *ngIf="configurableLengths?.itemprice; else caluclatePriceLabel" class="product-price">
              <lib-price [price]="configurableLengths.itemprice"></lib-price> netto / stk
            </h4>
            <ng-template #caluclatePriceLabel>
              <h4>
                Produktet må beregnes for å få prisen.
              </h4>
            </ng-template>
          </ng-template>
        </div>
      </div>
 -->

      <div *ngIf="!isOnlyDisplayFlow && !isAnonymous">
        <div class="row" *ngIf="configurableLengths; else normalBuyBtb">
          <div class="col-12 col-md-3">
            <div class="form-group">
              <label class="col-form-label label-length">
                {{ ('LENGTH' | translate) + ' (' + product.extra.ar?.enhetskod + ')' | translate }}
              </label>
              <input
                #inputEle
                type="number"
                class="form-control"
                [ngModel]="configurableLengths.length"
                (ngModelChange)="configurableLengths.length = $event"
                (blur)="
                  configurableLengths.length = formatValue(
                    configurableLengths.length,
                    product.extra.ar?.q_salesbatchsize.toString().split('.')[1]?.length || 0
                  );
                  onLengthChange(inputEle)
                "
                (click)="$event.preventDefault(); $event.stopPropagation()"
                [step]="Number(product.extra.ar?.q_salesbatchsize) || 1"
              />
            </div>
          </div>
          <div class="col-12 col-md-3">
            <lib-gung-number-input

              #qtyRef
              gungTranslate="QUANTITY"
              [(ngModel)]="configurableLengths.quantity"
              [maxValue]="Number(product.extra.ar?.artfsgforp)"
              [decimals]="0"
              (ngModelChange)="onQuantityChange(qtyRef)"
            >
            </lib-gung-number-input>
          </div>
          <div class="col-12 col-md-3 d-flex align-items-center">
            <button
              *ngIf="!configurableLengths?.itemno"
              type="button"
              class="btn btn-primary mt-1 w-100"
              translate
              [disabled]="configurableLengths?.itemno || !configurableLengths.length || !configurableLengths.quantity"
              (click)="calculatePrice(configurableLengths.length, configurableLengths.quantity, $event)"
            >
              CALCULATE_PRICE
            </button>
            <button
              *ngIf="configurableLengths?.itemno"
              type="button"
              class="btn btn-primary mt-2 w-100"
              translate
              [disabled]="!configurableLengths?.itemno ? true : undefined"
              (click)="addConfigurableLengths(configurableLengths.length, configurableLengths.quantity)"
            >
              ADD
            </button>
          </div>
          <div class="col-12" *ngIf="showLenghtWarning">
            <div class="alert alert-warning" role="alert">
              <span>
                <b translate>INFO</b>: <br /><span translate>PACK_SIZE_IS</span>
                {{ product?.extra.ar?.artfsgforp | number }}
                <lib-gung-meta-output
                  [showOnlyText]="true"
                  [value]="product.extra?.ar?.enhetskod"
                  metadataTable="xen"
                  metaOptionName="enhetskodbeskr"
                ></lib-gung-meta-output>
              </span>
            </div>
          </div>

          <div class="col-12" *ngIf="showProductAddedToCart">
            <div class="alert alert-warning" role="alert">
              <span>
                <b translate>PRODUCT_CONFIGURABLE_ADDED</b>: <br /><span translate
                  >PRODUCT_CONFIGURABLE_ADDED_TEXT</span
                >
              </span>
            </div>
          </div>

          <otto-olsen-availability-warehouses
            [availability]="availability"
            [isSales]="isSales"
            [unit]="product.extra.ar?.enhetskod"
            [details]="true"
            [decimals]="product.extra.ar.antdec"
            [product]="product"
            [productionDays]="
              product?.extra.ar?.anskaffningssatt &&
              product?.extra.ar?.anskaffningssatt * 1 === 12 &&
              product?.extra.ar?.artgenomloppstid
            "/>
        </div>
        <ng-template #normalBuyBtb>
          <div class="col-12" *ngIf="!isOnlyDisplayFlow && !isAnonymous">
            <div
              *ngIf="
                !(product.extra.pim && product.extra.pim.pricerequest && product.extra.pim.pricerequest !== 'false') &&
                  !(product.extra.ar.itemtypecd1 === 0 || product.extra.ar.itemtypecd1 === 7);
                else priceRequest
              "
            >
              <div class="buy-info">
                <div class="d-flex">
                  <div class="buy-btn">
                    <otto-olsen-buy-btn
                      name="buy-btn-package-size"
                      [id]="product.id"
                      [minimumOrderQuantity]="product.firstStepAmount || product.packageSize"
                      [stepAmount]="product.packageSize"
                      [decimals]="product.extra.ar.antdec"
                      [originalSize]="product.originalSize"
                      [unit]="product.extra.ar?.enhetskod"
                      [activeInB2B]="product?.extra?.pim?.activeInB2B"
                      [product]="product"
                      [availability]="availability"
                      (quantityUpdatedBuyBtn)="getPrice($event.qty)"
                    >
                    </otto-olsen-buy-btn>
                  </div>
                  <!-- <div class="stock-info">
                  <div class="row" *ngIf="!isAnonymous">
                    <div class="col-12">
                      <i class="fas fa-box"></i>&nbsp;
                      <otto-olsen-availability
                        [availability]="availability"
                        [isSales]="isSales"
                        [unit]="product.extra.ar?.enhetskod"
                        [details]="true"
                        [decimals]="product.extra.ar.antdec"
                        [productionDays]="
                          product?.extra.ar?.anskaffningssatt &&
                          product?.extra.ar?.anskaffningssatt * 1 === 12 &&
                          product?.extra.ar?.artgenomloppstid
                        "
                      ></otto-olsen-availability>
                    </div>
                  </div>
                </div> -->
                </div>

                <div class="totals mt-2" *ngIf="productInCart">
                  <div class="net-price">
                    <span translate>TOTAL</span>: {{ totalBuyThisProduct.total | number: '1.2-2' : '' : '.' }}
                    {{ price.customerNetPrice.currencyCode }} ({{ productInCart.qty }}
                    {{ product.extra?.ar?.enhetskod }})
                    <span *ngIf="cuttingFeePrice && productInCart.qty % packageSize !== 0">
                      + {{ cuttingFeePrice | number: '1.2-2' : '' : '.' }} {{ price.customerNetPrice.currencyCode }}
                      <span translate>IN_CUTTING_FEE</span>
                    </span>
                  </div>
                  <div class="gross-price" *ngIf="price.customerDiscountPercent !== 0">
                    <span translate>REC_PRICE</span>: {{ totalBuyThisProduct.totalRec | number: '1.2-2' : '' : '.' }}
                    {{ price.customerGrossPrice.currencyCode }}
                  </div>
                </div>

                <div class="no-print">
                  <otto-olsen-availability-warehouses
                    [availability]="availability"
                    [isSales]="isSales"
                    [unit]="product.extra.ar?.enhetskod"
                    [details]="true"
                    [decimals]="product.extra.ar.antdec"
                    [product]="product"
                    [productionDays]="
                      product?.extra.ar?.anskaffningssatt &&
                      product?.extra.ar?.anskaffningssatt * 1 === 12 &&
                      product?.extra.ar?.artgenomloppstid
                    "
                  ></otto-olsen-availability-warehouses>
                </div>

                <div class="row mt-3" style="max-width: 525px;" *ngIf="!isAnonymous">
                  <div class="col-12 d-flex flex-row justify-content-start justify-content-md-end">
                    <otto-olsen-favourite-product-icon class="ml-md-auto" [id]="product.id"></otto-olsen-favourite-product-icon>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #priceRequest>
              <div class="col-12 col-md-6">
                <a
                  href="mailto:{{ requestPriceEmail }}?subject=Forespørsel {{ product.id }}"
                  target="_blank"
                  class="request-price-email"
                >
                  <button type="button" class="btn btn-primary" translate>ASK_PRICE</button>
                </a>
              </div>
            </ng-template>
          </div>
        </ng-template>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <ul>
            <li *ngFor="let bullet of product.extra.bullets">{{ bullet }}</li>
          </ul>
        </div>
      </div>
      <div class="row" *ngIf="product?.description">
        <div class="col-12">
          <!-- <p>{{ product.description }}</p> -->
          <div
            *ngIf="!product?.extra.descriptionShort || product?.extra.hideShort; else descriptionShort"
            [innerHTML]="product.description | safeHtml"
          ></div>
          <button
            *ngIf="product?.extra.descriptionShort && product?.extra.hideShort"
            (click)="product?.extra.hideShort = false"
            type="button"
            class="btn btn-sm btn-link border my-3 py-0 px-5"
          >
            <span class="cursor-pointer font-weight-bold" translate>SHOW_LESS</span
            ><i class="fa-solid fa-chevron-up ml-2"></i>
          </button>
          <ng-template #descriptionShort>
            <div [innerHTML]="product.extra.descriptionShort | safeHtml"></div>
            <button
              type="button"
              class="btn btn-sm btn-link border my-3 py-0 px-5"
              (click)="product?.extra.hideShort = true"
            >
              <span class="cursor-pointer font-weight-bold" translate>SHOW_MORE</span
              ><i class="fa-solid fa-chevron-down ml-2"></i>
            </button>
          </ng-template>
        </div>
      </div>
      <div class="row" *ngIf="product?.documents && product?.documents.length > 0">
        <div class="col-12 mt-2">
          <div class="table-responsive">
            <table class="table table-borderless table-striped table-sm table-layout-fixed">
              <thead>
                <tr>
                  <th scope="col" translate>DOCUMENT</th>
                  <th scope="col" translate>DOWNLOAD_DOCUMENT</th>
                </tr>
              </thead>

              <tbody>
                <tr *ngFor="let doc of product?.documents">
                  <td translate>
                    {{ doc.name }}
                    <i title="Internal" *ngIf="doc.extra?.permission === 'Internal'" class="fa-solid fa-house-lock"></i>
                  </td>
                  <td>
                    <a href="{{ doc.url }}" target="_blank" translate>DOWNLOAD</a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="models3d && models3d.length > 0">
        <div class="col-12 mt-2">
          <div class="table-responsive">
            <table class="table table-borderless table-striped table-sm table-layout-fixed">
              <thead>
                <tr>
                  <th scope="col" translate>3D_MODEL</th>
                  <th scope="col" translate>DOWNLOAD_3D_MODEL</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td translate>FORMAT</td>
                  <td>
                    <span *ngFor="let model of models3d; let i = index">
                      <a href="{{ model }}" target="_blank">{{
                        model.split('/').pop().split('.').pop().toLowerCase()
                      }}</a>
                      {{ i === models3d.length - 1 ? '' : '&nbsp;-&nbsp;' }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="productDetailsTable?.length > 0" class="row mt-3 product-detail-data-section">
    <div class="col-12 col-md-6 product-tabel-detail" *ngFor="let item of productDetailsTable">
      <!-- <lib-gung-text-input-horizontal renderStatic="true" gungTranslate="{{item.title}}" [(ngModel)]="item.value"></lib-gung-text-input-horizontal> -->
      <div class="form-group row">
        <label class="col-form-label" [ngClass]="'col-12 col-sm-4'" translate>{{ item.title }}</label>
        <div class="col">
          <span>
            {{ item.value }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="addonIds.length > 0 || pimRelatedProductsIds.length > 0" class="row mt-3 product-detail-data-section">
    <div class="col-12">
      <lib-gung-tabset *ngIf="addonIds.length > 0 || pimRelatedProductsIds.length > 0">
        <lib-gung-tab *ngIf="addonIds.length > 0" [title]="'ADDONS' | translate">
          <ng-template libGungTabContent>
            <lib-product-list-by-ids [productIds]="addonIds"></lib-product-list-by-ids>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab *ngIf="pimRelatedProductsIds.length > 0" [title]="'PRODUCT_RELATED_PRODUCTS' | translate">
          <ng-template libGungTabContent>
            <lib-product-list-by-ids [productIds]="pimRelatedProductsIds"></lib-product-list-by-ids>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
</div>
<div class="only-print">
  <p class="text-print-description">
    Dette er et automatisk generert produktark fra webshop.oo.no. Produktarket kan inneholde feil eller mangler og
    benyttes på eget ansvar. Ved uklarheter, feil eller mangler vennligst kontakt Otto Olsen AS
  </p>
  <div class="footer-print">
    <div class="container">
      <div class="row margin-print-footer">
        <div class="col-4 text-left">
          <strong>Otto Olsen AS</strong><br />
          Trodheimsveien 183<br />
          2020 Skedsmokorset
        </div>
        <div class="col-4 text-center">
          <br />
          www.oo.no<br />
          post&#64;oo.no
        </div>
        <div class="col-4 text-right">
          <br />
          Telefon: 63 89 08 00<br />
          NO 980 350 665 MVA
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div *ngIf="!isProductAccessForbidden; else isAccessForbidden">LOADING...</div>
</ng-template>

<ng-template #isAccessForbidden>
  <span translate>ACCESS_FORBIDDEN_ERROR</span>
</ng-template>
