<div *ngIf="modelFile; else noFile" class="online_3d_viewer" [id]="viewerId" [style.width]="width" [class.d-none]="loadingModel"
  [style.height]="height" [attr.model]="modelFile?.toString()">
  <div *ngIf="!loadingModel && !isExpanded" class="p-1 expand-to-new-window" (click)="openModal()"><i
      class="fa-solid fa-expand"></i></div>
  <div *ngIf="!loadingModel && showMoreOptions" class="viewer3d-menu position-absolute d-flex flex-row p-2">
    <button class="viewer3d-menu-button" (click)="setYAxisUpVector()" placement="bottom"
      [ngbTooltip]="'Y_AXIS_UP_VECTOR' | translate" translate>
      <i class="fa-light fa-y"></i>
    </button>
    <button class="viewer3d-menu-button" (click)="setZAxisUpVector()" placement="bottom"
      [ngbTooltip]="'Z_AXIS_UP_VECTOR' | translate" translate>
      <i class="fa-light fa-z"></i>
    </button>
    <button class="viewer3d-menu-button" (click)="flipUpVector()" placement="bottom"
      [ngbTooltip]="'FLIP_UP_VECTOR' | translate" translate>
      <i class="fa-light fa-arrow-up-arrow-down"></i>
    </button>
    <div class="viewer3d-menu-separator"></div>
    <button class="viewer3d-menu-button" [ngClass]="{'toggled': embeddedViewer.viewer.upVector.isFixed === true}"
      (click)="fixedVectorMovement()" placement="bottom" [ngbTooltip]="'FIXED_UP_VECTOR' | translate" translate>
      <i class="fa-light fa-arrow-up"></i>
    </button>
    <button class="viewer3d-menu-button" [ngClass]="{'toggled': embeddedViewer.viewer.upVector.isFixed === false}"
      placement="bottom" [ngbTooltip]="'FREE_ORBIT' | translate" (click)="freeOrbitMovement()" translate>
      <i class="fa-light fa-planet-ringed"></i>
    </button>
    <div class="viewer3d-menu-separator"></div>
    <button class="viewer3d-menu-button" [ngClass]="{'toggled': cameraMode === 1}" (click)="perspectiveCamera()"
      placement="bottom" [ngbTooltip]="'PERSPECTIVE_CAMERA' | translate" translate>
      <i class="fa-light fa-cube"></i>
    </button>
    <button class="viewer3d-menu-button" [ngClass]="{'toggled': cameraMode === 2}" (click)="orthographicCamera()"
      placement="bottom" [ngbTooltip]="'ORTHOGRAPHIC_CAMERA' | translate" translate>
      <i class="fa-light fa-square"></i>
    </button>
  </div>
</div>
<ng-template #noFile>
  <span translate>NO_FILE_FOUND</span>
</ng-template>
<div class="loading position-relative d-flex justify-content-center align-items-center" *ngIf="loadingModel" [style.width]="width" [style.height]="height">
  <div class='tetrominos'>
    <div class='tetromino box1'></div>
    <div class='tetromino box2'></div>
    <div class='tetromino box3'></div>
    <div class='tetromino box4'></div>
  </div>
</div>