import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderListComponent } from 'gung-standard';
import { OttoOlsenDeliveryOverviewListConfigService } from '../../services/otto-olsen-delivery-overview-list-config.service';

@Component({
  selector: 'otto-olsen-delivery-overview',
  templateUrl: './otto-olsen-delivery-overview.component.html',
  styleUrl: './otto-olsen-delivery-overview.component.scss'
})
export class OttoOlsenDeliveryOverviewComponent extends OrderListComponent {
  constructor(
    public orderListConfigService: OttoOlsenDeliveryOverviewListConfigService,
    protected route: ActivatedRoute
  ) {
    super(
      orderListConfigService,
      route
    );
  }
}
