<h4>{{ 'CREATE_BLOCK_ORDER_AGREEMENTS' | translate }}</h4>
@if (form) {
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-group">
        <!-- <label for="customerId" class="control-label" translate>CUSTOMER</label>
        <input class="form-control" type="text" name="customerId" id="customerId" [(ngModel)]="customerId" /> -->
        <lib-gung-input-multi-select
          label="CUSTOMER"
          [optionsList]="customerOptionsList"
          (selectedValues)="setSelectedCustomerId($event)"
          [singleSelection]="true"
          [previewField]="['id', ' - ', 'name']"
          [showEmptyIds]="true"
        ></lib-gung-input-multi-select>
        <div *ngIf="saveClick && !customerId" class="alert alert-danger" translate>FORM_ERROR_REQUIRED</div>
      </div>
      <div class="form-group">
        <lib-gung-date-picker
          label="OFFER_DATE"
          [currentselectedDate]="form.get('OffertDat').value"
          (selectedDate)="handleDateSelected($event, 'OffertDat')"
        ></lib-gung-date-picker>
        <lib-forms-message-error [controller]="form.get('OffertDat')"></lib-forms-message-error>
      </div>
      @if (form.get('OrdTyp').value !== '970' && form.get('OrdTyp').value !== '973') {
        <div class="form-group">
          <lib-gung-date-picker
            label="DATE_OF_REMINDER"
            [currentselectedDate]="form.get('q_jis_reminderdate').value"
            (selectedDate)="handleDateSelected($event, 'q_jis_reminderdate')"
          ></lib-gung-date-picker>
          <lib-forms-message-error [controller]="form.get('q_jis_reminderdate')"></lib-forms-message-error>
        </div>
      }
      <div class="form-group" [formGroup]="form">
        <label for="KundRef2" class="control-label" translate>THEIR_REFERENCE</label>
        <input class="form-control" type="text" name="KundRef2" id="KundRef2" [formControlName]="'KundRef2'" />
        <lib-forms-message-error [controller]="form.get('KundRef2')"></lib-forms-message-error>
      </div>
      <div class="form-group" [formGroup]="form">
        <label for="email1" class="control-label" translate>EMAIL1_AGREEMENT:</label>
        <input class="form-control" type="text" name="email1" id="email1" [formControlName]="'email1'" />
        <lib-forms-message-error [controller]="form.get('email1')"></lib-forms-message-error>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group" [formGroup]="form">
        <label class="control-label" for="OrdTyp" translate>ORDER_TYPE</label>
        <lib-meta-select-input
          [suppressLabel]="true"
          formControlName="OrdTyp"
          table="x6"
          metaId="ordtyp"
          metaDisplay="ordtypbeskr"
          [optionsFilterFunction]="filterOrderType"
        >
        </lib-meta-select-input>
        <lib-forms-message-error [controller]="form.get('OrdTyp')"></lib-forms-message-error>
      </div>
      <div class="form-group">
        @if (form.get('OrdTyp').value === '970' || form.get('OrdTyp').value === '973') {
          <div [formGroup]="form">
            @if(form.get('OffertDat').value){
              <lib-gung-select-option-input
                gungTranslate="VALID_BLANK_DATE"
                [optionsList]="dateValues"
                formControlName="dateValues"
              >
              </lib-gung-select-option-input>
            }
            <lib-forms-message-error [controller]="form.get('GiltigTomDatum')"></lib-forms-message-error>
          </div>
        } @else {
            <lib-gung-date-picker
            label="VALID_BLANK_DATE"
            [currentselectedDate]="form.get('GiltigTomDatum').value"
            (selectedDate)="handleDateSelected($event, 'GiltigTomDatum')"
          ></lib-gung-date-picker>
          
          <lib-forms-message-error [controller]="form.get('GiltigTomDatum')"></lib-forms-message-error>
        }
      </div>

      <div class="form-group" [formGroup]="form">
        <label for="address" class="control-label" translate>SUPER_OFFICE_AGREEMENT_NUMBER</label>
        <input class="form-control" type="text" name="OffBeskr" id="OffBeskr" [formControlName]="'OffBeskr'" />
        <lib-forms-message-error [controller]="form.get('OffBeskr')"></lib-forms-message-error>
      </div>

      <div class="form-group" [formGroup]="form">
        <label for="KundBestNr" class="control-label" translate>CUSTOMER_ORDER_NUMBER</label>
        <input class="form-control" type="text" name="KundBestNr" id="KundBestNr" [formControlName]="'KundBestNr'" />
        <lib-forms-message-error [controller]="form.get('KundBestNr')"></lib-forms-message-error>
      </div>

      <div class="form-group" [formGroup]="form">
        <label for="email2" class="control-label" translate>EMAIL2_AGREEMENT:</label>
        <input class="form-control" type="text" name="email1" id="email2" [formControlName]="'email2'" />
        <lib-forms-message-error [controller]="form.get('email2')"></lib-forms-message-error>
      </div>
    </div>
  </div>
  <h4>{{ 'BLOCK_ORDER_LINES' | translate }}</h4>
  @for (row of rows; track index; let index = $index) {
    <div class="row">
      <div class="col-12 col-md-2">
        <div class="form-group">
          <label for="typeahead-prevent-manual-entry" translate>ITEM_NO</label>
          <input
            #productElement
            id="typeahead-prevent-manual-entry_{{ index }}"
            type="text"
            class="form-control"
            [(ngModel)]="productSelect[index]"
            [ngbTypeahead]="search"
            [inputFormatter]="formatter"
            [resultFormatter]="formatter"
            (selectItem)="selectProduct($event, index)"
            [editable]="false"
          />
          <div *ngIf="saveClick && !row.productId" class="text-danger" translate>FORM_ERROR_REQUIRED</div>
        </div>
      </div>
      <div class="col-12 col-md-2">
        {{ form.submitted }}
        <div class="form-group">
          <label for="quantity_{{ index }}" class="control-label" translate>QUANTITY</label>
          <input
            class="form-control"
            type="number"
            name="quantity_{{ index }}"
            id="quantity_{{ index }}"
            [(ngModel)]="row.quantity"
          />
          <div *ngIf="saveClick && (!row.quantity || row.quantity < 1)" class="text-danger" translate>
            FORM_ERROR_REQUIRED
          </div>
        </div>
      </div>
      @if (form.get('OrdTyp').value !== '973') {
        <div class="col-12 col-md-2">
          <div class="form-group">
            <label for="quantity_{{ index }}" class="control-label" translate>Q_OO_OFHEOQ</label>
            <input
              class="form-control"
              type="number"
              name="quantity_{{ index }}"
              id="return_quantity_{{ index }}"
              [(ngModel)]="row.extra.procargs.q_oo_ofheoq"
            />
            <div
              *ngIf="saveClick && (!row.extra.procargs.q_oo_ofheoq || row.extra.procargs.q_oo_ofheoq < 1)"
              class="text-danger"
              translate
            >
              FORM_ERROR_REQUIRED
            </div>
          </div>
        </div>
      }

      <div class="col-12 col-md-2">
        <div class="form-group">
          <label for="agreement_forecast{{ index }}" class="control-label" translate>AGREEMENT_FORECAST</label>
          <input
            class="form-control"
            type="number"
            name="agreement_forecast{{ index }}"
            id="agreement_forecast{{ index }}"
            [(ngModel)]="row.extra.procargs.q_oo_ofhprognose"
          />
          <div *ngIf="saveClick && !row.extra.procargs.q_oo_ofhprognose" class="text-danger" translate>
            FORM_ERROR_REQUIRED
          </div>
        </div>
      </div>
      <div class="col-12 col-md-2">
        <div class="form-group">
          <label for="sales_price{{ index }}" class="control-label" translate>SALE_PRICE</label>
          <input
            class="form-control"
            type="text"
            name="sales_price{{ index }}"
            id="sales_price{{ index }}"
            [(ngModel)]="row.extra.procargs.vb_pris"
          />
          <div *ngIf="saveClick && !row.extra.procargs.vb_pris" class="text-danger" translate>FORM_ERROR_REQUIRED</div>
        </div>
      </div>
      <div class="col-12 col-md-2">
        @if (rows.length > 1) {
          <gung-button
            class="row-action-btn"
            classes="btn-secondary btn w-100 "
            [onClick]="removeRow.bind(this, index)"
            label="{{ 'REMOVE_ROW' | translate }}"
            [disable]="loading"
          />
        }
        @if (index === rows.length - 1) {
          <gung-button
            class="row-action-btn"
            classes="btn-secondary btn w-100"
            [onClick]="addRow.bind(this)"
            label="{{ 'ADD_ROW' | translate }}"
            [disable]="loading"
          />
        }
      </div>
    </div>
    <hr />
  }

  <gung-button
    classes="btn-primary btn w-100"
    [onClick]="save.bind(this)"
    label="{{ 'SAVE' | translate }}"
    [loading]="loading"
  />
}
