import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, SelectedCustomerService } from 'gung-standard';
import { BehaviorSubject, filter, first, Observable, switchMap } from 'rxjs';

interface OttoOlsenRowBlockOrder {
  id: string;
  productId: string;
  quantity: number;
  extra: { [s: string]: any };
}

export interface OttoOlsenBlockOrder {
  id: string;
  customerId: string;
  extra: { [s: string]: any };

}

export interface OttoOlsenOrderAgreement{
  id: string;
  deliveryCustomerId: string;
  extra: { [s: string]: any };
  rows: OttoOlsenRowBlockOrder;
}


@Injectable({
  providedIn: 'root'
})
export class OttoOlsenBlockOrderAgreementsService {

  constructor(protected http: HttpClient, protected authService: AuthService, protected selectedCustomerService: SelectedCustomerService) {}
  customerid: string;
  private blockOrderList: BehaviorSubject<OttoOlsenBlockOrder[]>;

  updateBlockOrderListSubject(customerId: string): void {
    this.getServerBlockOrders(customerId).subscribe(block => {
      this.blockOrderList?.next(block)
    });
  }

  getBlockOrders(customerId = null): Observable<OttoOlsenBlockOrder[]> {
    if (!this.blockOrderList || this.customerid !== customerId) {
      this.blockOrderList = new BehaviorSubject<OttoOlsenBlockOrder[]>(null);
      this.updateBlockOrderListSubject(customerId);
    }
    return this.blockOrderList.asObservable().pipe(filter(blockOrder => !!blockOrder));
  }


  private getServerBlockOrders(customerId: string): Observable<OttoOlsenBlockOrder[]> {
    const url = `json/otto/block-order-agreements`;
    const headers = { maxAge: '-1' };
    if(customerId){
      const urlCustomer = `${url}/for-customer/${customerId}`;
      return this.http.get<OttoOlsenBlockOrder[]>(urlCustomer, { headers });
    }
    return this.authService.getRoles().pipe(first(),
    switchMap(roles =>{
      if(roles.includes('ADMIN')){
        return this.http.get<OttoOlsenBlockOrder[]>(url, { headers });
      }
      return this.selectedCustomerService.getSelectedCustomer().pipe(first(),
        switchMap(customer => {
          const urlCustomer = `${url}/for-customer/${customer.id}`;
          return this.http.get<OttoOlsenBlockOrder[]>(urlCustomer, { headers });
        })
      )
    })
    )
    
  }

  downloadDocument(id): any{
    return this.http.get<any>(`download/agreement-pdf/${id}`);
  }

}
