import s from "../helpers/QRLoaderContainer/QRLoaderContainer.js";
import { TxVariants as e } from "../tx-variants.js";
class r extends s {
  static type = e.swish;
  formatProps(s) {
    return {
      delay: 2e3,
      countdownTime: 3,
      instructions: "swish.pendingMessage",
      ...super.formatProps(s)
    };
  }
}
export { r as default };
