import { Component, Input, OnInit } from '@angular/core';
import { OttoOlsenBlockOrderAgreementsService } from '../../services/otto-olsen-block-order-agreements.service';
import { OttoOlsenBlockOrderAgreementsListConfigService } from '../../services/otto-olsen-block-order-agreements-list-config.service';
import { AuthService } from 'gung-standard';
import { first } from 'rxjs';

@Component({
  selector: 'otto-block-order-agreements',
  templateUrl: './otto-block-order-agreements.component.html',
  styleUrl: './otto-block-order-agreements.component.scss'
})
export class OttoBlockOrderAgreementsComponent implements OnInit {

  isAdmin = false;

  @Input() customerId?: string

  constructor(
    protected blockOrderAgreementsService: OttoOlsenBlockOrderAgreementsService,
    public configService: OttoOlsenBlockOrderAgreementsListConfigService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.configService.customerId = this.customerId;
    this.authService.getRoles().pipe(first()).subscribe(roles =>{
      if(roles.includes('ADMIN')){
        this.isAdmin = true;
      }
    })  
  }

  create(){
    
  }
}
