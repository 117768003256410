<h3 translate class="page-title text-center">EXPORT_TEMPLATES</h3>
<button *ngIf="enableCreateTemplate" class="btn btn-primary" (click)="createNewXlsxModal()">
CREATE_NEW_XLSX_TEMPLATE
</button>
<div *ngIf="templatesList != null; else loading">
  <div class="row mt-2 mb-2">
    <div class="col">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="{ 'alert-success': alertMessage.type === 'success', 'alert-danger': alertMessage.type === 'danger' }"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <div class="toolbar">
        <div
          *ngFor="let option of templatesList; trackBy: trackByFn"
          class="template-menu border border-primary pointer"
          (click)="setTemplate(option.id)"
          [class.active]="option.id === templateId"
          [class.btn-primary]="option.id === templateId"
        >
          <span translate>{{ option.name }}</span>
          <span class="side-nav-spacer"></span>
          <i
            *ngIf="editedTemplatesId.includes(option.id)"
            class="fas fa-exclamation-triangle"
            title="{{ 'UNSAVED_CHANGES' | translate }}"
          ></i>
        </div>

        <div
          *ngIf="isActuator"
          class="border border-primary pointer mt-4"
          (click)="showAlias = true; templateId = null; currentTab = ''"
          [class.active]="showAlias === true"
          [class.btn-primary]="showAlias === true"
        >
          <span translate>ALIASES_SHORTHANDS</span>
          <span class="side-nav-spacer"></span>
          <i
            *ngIf="editedAliasId.length !== 0"
            class="fas fa-exclamation-triangle"
            title="{{ 'UNSAVED_CHANGES' | translate }}"
          ></i>
        </div>
      </div>
    </div>
    <div class="col-9">
      <div class="row mt-2 mb-2">
        <div class="col-9">
          <lib-filter-list-search (searchUpdated)="setSearch($event)"></lib-filter-list-search>
        </div>
        <div class="col-3">
          <div class="btn-group">
            <button
              type="button"
              class="btn btn-primary float-right"
              (click)="onSave()"
              [disabled]="loader ? true : null"
            >
              <span translate>SAVE</span><i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
            </button>
            <button
              *ngIf="isActuator"
              type="button"
              class="btn btn-outline-secondary float-right"
              (click)="addRow()"
              [disabled]="loader ? true : null"
            >
              <span translate>ADD_ROW</span>
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <lib-gung-text-input
              gungTranslate="TEMPLATE_NAME"
              [(ngModel)]="selectedTemplate.name"
              (change)="unsaved(selectedTemplate.id)"
            ></lib-gung-text-input>
          </div>
        </div>
        <div class="col-12 col-sm-6 d-flex justify-content-center">
          @if(isActuator && showAlias) {
            <button type="button" class="btn btn-primary align-self-center" (click)="syncPim()" translate>SYNC_ALIAS_EXPRESSIONS_FROM_PIM_FIELD_DEFINITIONS</button>
          }
        </div>
      </div>
      <div class="row" *ngIf="enableCreateTemplate">
        <div class="col-12">
          <div class="form-group">
            <lib-gung-checkbox-input-horizontal
              tooltip="IS_DYNAMIC_DOCUMENT_TEMPLATE_TOOLTIP"
              gungTranslate="IS_DYNAMIC_DOCUMENT_TEMPLATE"
              [(ngModel)]="selectedTemplate.showXlsxAutomatically"
              (change)="unsaved(selectedTemplate.id)"
            >
            </lib-gung-checkbox-input-horizontal>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group">
            <lib-gung-input-multi-select
              *ngIf="!!rolesList && selectedTemplate.showXlsxAutomatically"
              class="multi-select-user-groups"
              [optionsList]="rolesList"
              (selectedValues)="setSelectedValues($event)"
              label="AVAILABLE_TO_ROLES"
            >
            </lib-gung-input-multi-select>
          </div>
        </div>
      </div>
      <div *ngIf="showAlias" class="row">
        <div class="col-12">
          <table class="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col" class="text-center" id="idAlias"><span translate>ALIAS</span></th>
                <th scope="col" class="text-center" id="idExpression"><span translate>EXPRESSION</span></th>
              </tr>
            </thead>
            <tbody class="table-list">
              <tr class="row-list" *ngFor="let alias of filteredAlias; index as i">
                <td class="text-center" headers="idAlias" width="50%">
                  <lib-gung-text-input
                    class="w-100"
                    [class.text-center]="false"
                    [gungTranslate]="'ALIAS'"
                    [(ngModel)]="alias.id"
                    [renderStatic]="loader ? true : null"
                    [suppressLabel]="true"
                    (change)="unsaved(alias.id)"
                  ></lib-gung-text-input>
                </td>
                <td class="text-center" headers="idExpression" width="50%">
                  <lib-gung-text-input
                    class="w-100"
                    [class.text-center]="false"
                    [gungTranslate]="'EXPRESSION'"
                    [(ngModel)]="alias.expression"
                    [renderStatic]="loader ? true : null"
                    [suppressLabel]="true"
                    (change)="unsaved(alias.id)"
                  ></lib-gung-text-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-12">
      <lib-gung-tabset *ngIf="!showAlias" justify="fill" style="width: 100%;" (tabChange)="handleTabChange($event)">
        <lib-gung-tab [id]="'lines'" [title]="'LINES' | translate">
          <ng-template libGungTabContent>
            <div class="row no-border-table">
              <div class="col-12">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center" id="idIclude"><span translate>INCLUDE</span></th>
                      <th *ngIf="isActuator" scope="col" class="text-center" id="idIgnoreErrors">
                        <span translate>IGNORE_ERRORS</span>
                      </th>
                      <th scope="col" class="text-left" id="idTitle"><span translate>TITLE</span></th>
                      <th scope="col" class="text-left" id="idExpression"><span translate>EXPRESSION</span></th>
                      <th *ngIf="isActuator" scope="col" class="text-center" id="idEdit"><span translate>TOOLS</span></th>
                      <th scope="col" class="text-center"><span translate>MOVE</span></th>
                    </tr>
                  </thead>
                  <tbody cdkDropList class="table-list" (cdkDropListDropped)="drop($event)" [cdkDropListAutoScrollStep]="20">
                    <tr class="row-list" *ngFor="let row of filteredRows; index as i" cdkDrag>
                      <td colspan="6" class="p-0" *cdkDragPlaceholder>
                        <div class="row-ist-placeholder"></div>
                      </td>
                      <td class="text-center" headers="idIclude" width="10%">
                        <input type="checkbox" [(ngModel)]="row.active" [disabled]="loader ? true : null" (change)="unsaved(selectedTemplate.id)"/>
                      </td>
                      <td *ngIf="isActuator" class="text-center" headers="idIgnoreErrors" width="10%">
                        <input type="checkbox" [(ngModel)]="row.ignoreErrors" [disabled]="loader ? true : null" (change)="unsaved(selectedTemplate.id)"/>
                      </td>
                      <td class="text-center" headers="idTitle" width="20%">
                        <lib-gung-text-input
                          class="w-100"
                          [class.text-center]="isActuator"
                          [gungTranslate]="'TITLE'"
                          [(ngModel)]="row.title"
                          [renderStatic]="loader ? true : undefined"
                          [suppressLabel]="true"
                          (change)="unsaved(selectedTemplate.id)"
                        >
                        </lib-gung-text-input>
                      </td>
                      <td class="text-center" headers="idExpression" width="40%">
                        <lib-gung-text-input
                          class="w-100"
                          [class.text-center]="isActuator"
                          [gungTranslate]="'TITLE'"
                          [(ngModel)]="row.expression"
                          [renderStatic]="loader ? true : undefined"
                          [suppressLabel]="true"
                          (change)="unsaved(selectedTemplate.id)"
                        >
                        </lib-gung-text-input>
                      </td>
                      <td *ngIf="isActuator" class="text-center" headers="idEdit" width="20%">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-outline-primary"
                            name="Edit"
                            (click)="clickEdit(i)"
                            [disabled]="loader ? true : null"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-primary"
                            name="Clone"
                            (click)="clickClone(i)"
                            [disabled]="loader ? true : null"
                          >
                            <i class="fas fa-clone"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            name="Remove"
                            (click)="clickRemove(i)"
                            [disabled]="loader ? true : null"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                      <td
                        class="text-center handle-list"
                        width="10%"
                        cdkDragHandle
                        [cdkDragHandleDisabled]="rows.length !== filteredRows.length || loader"
                        [class.cdk-drag-handle-disabled]="rows.length !== filteredRows.length || loader"
                      >
                        <i class="fas fa-arrows-alt"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [id]="'header'" [title]="'HEADER' | translate">
          <ng-template libGungTabContent>
            <div class="row no-border-table">
              <div class="col-12">
                <table class="table table-striped table-hover">
                  <thead>
                    <tr>
                      <th scope="col" class="text-center" id="idIclude"><span translate>INCLUDE</span></th>
                      <th *ngIf="isActuator" scope="col" class="text-center" id="idIgnoreErrors">
                        <span translate>IGNORE_ERRORS</span>
                      </th>
                      <th scope="col" class="text-left" id="idTitle"><span translate>TITLE</span></th>
                      <th scope="col" class="text-left" id="idExpression"><span translate>EXPRESSION</span></th>
                      <th *ngIf="isActuator" scope="col" class="text-center" id="idEdit"><span translate>TOOLS</span></th>
                      <th scope="col" class="text-center" id="idMove"><span translate>MOVE</span></th>
                    </tr>
                  </thead>
                  <tbody cdkDropList class="table-list" (cdkDropListDropped)="dropHeaders($event)" [cdkDropListAutoScrollStep]="20">
                    <tr class="row-list" *ngFor="let row of filteredRowsHeaders; index as i" cdkDrag>
                      <td colspan="6" class="p-0" *cdkDragPlaceholder>
                        <div class="row-ist-placeholder"></div>
                      </td>
                      <td class="text-center" headers="idIclude" width="10%">
                        <input type="checkbox" [(ngModel)]="row.active" [disabled]="loader ? true : null" (change)="unsaved(selectedTemplate.id)" />
                      </td>
                      <td *ngIf="isActuator" class="text-center" headers="idIgnoreErrors" width="10%">
                        <input type="checkbox" [(ngModel)]="row.ignoreErrors" [disabled]="loader ? true : null" (change)="unsaved(selectedTemplate.id)" />
                      </td>
                      <td class="text-center" headers="idTitle" width="50%">
                        <lib-gung-text-input
                          class="w-100"
                          [class.text-center]="isActuator"
                          [gungTranslate]="'TITLE'"
                          [(ngModel)]="row.title"
                          [renderStatic]="isActuator || loader ? true : null"
                          [suppressLabel]="true"
                          (change)="unsaved(selectedTemplate.id)"
                        >
                        </lib-gung-text-input>
                      </td>
                      <td class="text-center" headers="idExpression" width="50%">
                        <lib-gung-text-input
                          class="w-100"
                          [class.text-center]="isActuator"
                          [gungTranslate]="'TITLE'"
                          [(ngModel)]="row.expression"
                          [renderStatic]="loader ? true : undefined"
                          [suppressLabel]="true"
                          (change)="unsaved(selectedTemplate.id)"
                        >
                        </lib-gung-text-input>
                      </td>
                      <td *ngIf="isActuator" class="text-center" headers="idEdit" width="20%">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-outline-primary"
                            name="Edit"
                            (click)="clickEditHeaders(i)"
                            [disabled]="loader ? true : null"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-outline-primary"
                            name="Clone"
                            (click)="clickCloneHeaders(i)"
                            [disabled]="loader ? true : null"
                          >
                            <i class="fas fa-clone"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-danger"
                            name="Remove"
                            (click)="clickRemoveHeaders(i)"
                            [disabled]="loader ? true : null"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                      <td
                        class="text-center handle-list"
                        headers="idMove"
                        width="10%"
                        cdkDragHandle
                        [cdkDragHandleDisabled]="rows.length !== filteredRows.length || loader"
                        [class.cdk-drag-handle-disabled]="rows.length !== filteredRows.length || loader"
                      >
                        <i class="fas fa-arrows-alt"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
