import { Component } from '@angular/core';
import { DateUtilService } from 'gung-common';
import { LocationConfigService, PriceConfigService } from 'gung-standard';
import { JeevesOrderActionConfigService, JeevesOrderCardListComponent } from 'gung-standard-jeeves';

@Component({
  selector: 'otto-olsen-delivery-overview-card-list',
  templateUrl: './otto-olsen-delivery-overview-card-list.component.html',
  styleUrl: './otto-olsen-delivery-overview-card-list.component.scss'
})
export class OttoOlsenDeliveryOverviewCardListComponent extends JeevesOrderCardListComponent {
  constructor(
    public configService: JeevesOrderActionConfigService,
    protected locationConfigService: LocationConfigService,
    public priceConfigService: PriceConfigService,
    public dateUtilService: DateUtilService
  ) {
    super(configService, locationConfigService, priceConfigService);
  }
}
