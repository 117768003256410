<div class="card" *ngFor="let user of data">
  <div class="card-body">
    <div class="card-title" *ngIf="user.name">
      <h5>{{ user.name }} <a class="customer-user-link ml-2" href="admin/users-details/{{ user.username }}" target="_blank"><i class="fa-regular fa-up-right-from-square"></i></a></h5>
      <lib-action-btn [item]="user" [config]="actionConfig"></lib-action-btn>
    </div>
    <div class="card-text">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="row pt-1">
            <div class="col-4">
              {{ 'USERNAME' | translate }}
            </div>
            <div class="col-8">
              {{ user.username }}
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-4">
              {{ 'EMAIL' | translate }}
            </div>
            <div class="col-8">
              {{ user.email }}
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="row pt-1">
            <div class="col-4">
              {{ 'NAME' | translate }}
            </div>
            <div class="col-8">
              {{ user.name }}
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-4">
              {{ 'PHONE' | translate }}
            </div>
            <div class="col-8">
              {{ user.phone }}
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-4">
              {{ 'ROLES' | translate }}
            </div>
            <div class="col-8">
              <div *ngIf="user.roles.includes('VIEW') else notViewer">
                {{ 'VIEWER_DESCRIPTION' | translate }}
              </div>
              <ng-template #notViewer>
                <div *ngIf="user.roles.length === 1 && user.roles.includes('USER'); else notOnlyUser">
                  {{ 'USER_DESCRIPTION' | translate }}
                </div>
                <ng-template #notOnlyUser>
                  {{ user.roles?.join(', ') }}
                </ng-template>
              </ng-template>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="(user?.extra?.createdAt && user?.extra?.createdBy) || user?.extra?.loginData" class="row mt-4 ml-0 mr-0 login-information">
        <div *ngIf="user?.extra?.loginData" class="col-md-6 col-xs-12 mb-3 pl-0 pr-0 d-flex flex-column">
          <span class="font-weight-bold" translate>SUCCESSFUL_LOGIN_ATEMPTS</span>
          <span><b>{{ user.extra.loginData.loginSucessAttempts }}</b>&nbsp;&nbsp;<span *ngIf="user.extra._successLatestLoginDays">(<span translate>LAST_LOGIN</span>: {{ user.extra._successLatestLoginDays }} - {{ user.extra.loginData.latestSuccessfulLogin | date: 'd.M.yyyy HH:mm' }})</span></span>
        </div>
        <div *ngIf="user?.extra?.loginData" class="col-md-6 col-xs-12 mb-3 pl-0 pr-0 d-flex flex-column">
          <span class="font-weight-bold" translate>FAILED_LOGIN_ATEMPTS</span>
          <span><b>{{ user.extra.loginData.loginFailAttempts }}</b>&nbsp;&nbsp;<span *ngIf="user.extra._failedLatestLoginDays">(<span translate>LAST_LOGIN</span>: {{ user.extra._failedLatestLoginDays }} - {{ user.extra.loginData.latestFailedLogin | date: 'd.M.yyyy HH:mm' }})</span></span>
        </div>
        <div *ngIf="user?.extra?.createdAt && user?.extra?.createdBy" class="col-12 mb-2 pl-0 pr-0 d-flex flex-column">
          <span><b translate>USER_WAS_CREATED_AT_BY</b> {{ user.extra.createdAt | date: 'd.M.yyyy HH:mm' }}, <span translate>BY</span> {{ user.extra.createdBy }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
