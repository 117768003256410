import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { GroupedBlockOrderAgreement } from '../../services/otto-olsen-block-order-agreements-list-config.service';
import { OttoOlsenBlockOrder, OttoOlsenBlockOrderAgreementsService } from '../../services/otto-olsen-block-order-agreements.service';
import { AuthService, AvailabilityService, MetadataService, ProductService } from 'gung-standard';
import { first, forkJoin } from 'rxjs';
import { Router } from '@angular/router';

interface BlockOrderAgreementType{
  id: number;
  name: string;
  groupedOrder: GroupedBlockOrderAgreement[]
}
@Component({
  selector: 'otto-olsen-block-order-table-list',
  templateUrl: './otto-olsen-block-order-table-list.component.html',
  styleUrl: './otto-olsen-block-order-table-list.component.scss'
})
export class OttoOlsenBlockOrderTableListComponent extends ListItemRendererComponent<GroupedBlockOrderAgreement[]> implements OnInit, OnChanges {

  customerDetails = true;
  showGroup: { [k: string]: boolean } = {};
  orderTypes = [970, 971, 972, 973 ];

  isSales = false;
  
  orders: BlockOrderAgreementType[] = []; 

  constructor(
    protected availabilityService: AvailabilityService, 
    protected authService: AuthService, protected productService: ProductService,
    protected router: Router,
    protected metadataService: MetadataService,
    protected blockOrderAgreementsService: OttoOlsenBlockOrderAgreementsService
  ){
    super()
  }
  

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(roles => this.isSales = roles.includes('SALES'));
    if(!this.router.url.includes('customer')){
      this.customerDetails = false;
    }

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.orders = [];
    for (const type of this.orderTypes) {
      const groupedOrder = this.data.filter(d => d.orderType === type);
      if(groupedOrder.length > 0){
        const orders: BlockOrderAgreementType = {
          id: type,
          name: this.metadataService.getMetadataValue('x6', 'ordtypbeskr', type.toString()),
          groupedOrder: groupedOrder
        } 
        this.orders.push(orders)
      }
      
    }
  }


  toggleLines(group: GroupedBlockOrderAgreement) {
    this.showGroup[group.agreementId] = !this.showGroup[group.agreementId];
    const getInfoIds = [];
    for (const row of group.rows) {
      if(!row.extra?._av){
        getInfoIds.push(row.extra.q_gung_ofh.artnr);
      }
    }
    if(getInfoIds && getInfoIds.length>0){
      forkJoin({
        products: this.productService.getFullProductsByIds(getInfoIds).pipe(first()),
        avs: this.availabilityService.getAvailabilities(getInfoIds).pipe(first())
      }).subscribe(({products ,avs})=>{
        for (const av of avs) {
          group.rows.find(r => r.extra.q_gung_ofh.artnr === av.productId).extra._av = av;
        }
        for (const p of products) {
          group.rows.find(r => r.extra.q_gung_ofh.artnr === p.id).extra._product = p;
        }
      })
    }
  }

  getPriceWithDiscount(row: OttoOlsenBlockOrder) {
    let price = row.extra.q_gung_ofh?.vb_pris || 0.0;
    let discountPercentage = row.extra.q_gung_ofh?.rabatt1 || 0.0;

    let discountFactor = 1 - discountPercentage / 100;
    return price * discountFactor;
  }

  download(id){
    console.log(id);
    this.blockOrderAgreementsService.downloadDocument(id).subscribe(_=>{})
  }

  upload(id){
    console.log(id);
  }
}
