{
  "ACCESSORIES": "Tillbehør",
  "ACTIONS": "Alternativ",
  "ACTIVE_IN_B2B": "Aktiv i B2B",
  "ACTIVE_IN_B2C": "Aktiv i B2C",
  "ADD_NEW_CONDITION": "Legg till villkår",
  "ADD_NEW_FIELD": "Legg till nytt felt",
  "ADD_QUERY": "Add query",
  "ADD_QUERY_PART": "Add query part",
  "ADMIN": "Admin",
  "ALL_FIELDS": "Alle felt",
  "ALL_INFO": "All info",
  "AL_ARTNRLEV": "Lev. Varenummer",
  "AL_FIELDS": "AL Fields",
  "AL_FTGNR": "Leverandør",
  "AL_LEDTIDENLLEV": "Ledetid i arbeidsdager fra leverandør",
  "AL_LEVSARTBESKR": "Lev. Varespesifikasjon",
  "AL_TRANSPORTTID": "Normal trasporttid i arbeidsdager",
  "AL_VALKOD": "Pris per antall (1/10/100 e.l.)",
  "AL_VB_INPRIS_NETTO": "Netto innkjøpspris (valuta)",
  "AND": "og",
  "AREA_OF_USE": "Anvendingsområdet",
  "ARTICLE_INFORMATION": "Produktinformasjon",
  "AR_ARTBESKR": "Varenavn",
  "AR_ARTBESKRSPEC": "Varespesifikasjon",
  "AR_ARTFSGFORP": "Pakkestørrelse",
  "AR_ARTNR": "Varenummer",
  "AR_ARTRABKLASS": "Rabattgruppe",
  "AR_ARTSTATNR": "HS-kode",
  "AR_ARTVIKT": "Vikt",
  "AR_EDIT": "Varebeskrivelse 2",
  "AR_ENHETSKOD": "Enhet",
  "AR_EXTRA1": "Søketekst",
  "AR_FTGNR": "Kunde",
  "AR_ITEMTYPECD1": "Valuta",
  "AR_LEVNR": "Leverandørnummer",
  "AR_URSPRUNGSLAND": "Opprinnelsesland",
  "AR_VARUGRUPPKOD": "Varegruppekode",
  "ASK_PRICE": "Pris på forespørsel",
  "ASSORTMENT": "Sortiment",
  "ASSORTMENTLEVEL1": "Sortimentsnivå 1",
  "ASSORTMENTLEVEL2": "Sortimentsnivå 2",
  "ASSORTMENTLEVEL3": "Sortimentsnivå 3",
  "ASSORTMENTLEVEL4": "Sortimentsnivå 4",
  "ASSORTMENTLEVEL5": "Sortimentsnivå 5",
  "ASSORTMENTLEVEL6": "Sortimentsnivå 6",
  "ASSORTMENTS": "Sortiment",
  "ASSORTMENT_BUILDER": "Sortimentsbygger",
  "ASSORTMENT_DESCRIPTION": "Sortimentsbeskrivning",
  "ASSORTMENT_DETAILS": "Sortimentsdetaljer",
  "ASSORTMENT_NAME": "Sortimentnavn",
  "ASSORTMENT_TEMPLATE": "Sortimentmal",
  "ASSORTMENT_TEMPLATES": "Sortimentmaler",
  "ATTRIBUTE": "Egenskaper",
  "ATTRIBUTES_MATCH_ALL_CONDITIONS": "Hvilke egenskaper samsvarer med ALLE villkår nedenfor",
  "AVAILABILITY_CONTACT_US": "Ved snarlig behov, kontakt oss.",
  "AVAILABILITY_ESTIMATED": "Normal levereringtid ved bestilling {{date}}.",
  "AVAILABILITY_ESTIMATED_CONTACT_US": "Normal levereringtid ved bestilling {{date}}. Ved snarlig behov, kontakt oss.",
  "AVAILABILITY_ESTIMATED_DATE": "{{qty}} {{unit}} tilgjengelig {{date}}. Avvik kan forekomme.",
  "AVAILABILITY_ESTIMATED_DATE_CONTACT_US": "Forventet leveringstid ved bestilling i dag er {{date}}. Ved snarlig behov, kontakt oss.",
  "AVAILABILITY_ESTIMATED_DATE_SHORT": "Tilgjengelig {{date}}. Avvik kan forekomme",
  "AVAILABILITY_DEVIATIONS_MAY_OCCUR": "Avvik kan forekomme",
  "AXIS": "Aksel",
  "BEARING_TYPE": "Lager type",
  "BENDING_RADIUS": "Bøyeradius",
  "BLOCKED_PRODUCTS": "Info",
  "BLOCKED_PRODUCTS_MESSAGE": "Produkt(ene) {{products}} er ikke tilgjengelig i nettbutikken. Vennligst kontakt kundeservice 63890800 eller ordre@oo.no",
  "BRAKE": "Brems",
  "BRAKE_CONTROL": "Bremsestyring",
  "BUILD_HEIGHT": "Byggehøyde mm",
  "BURST_PRESSURE": "Sprengtrykk Bar",
  "CANCEL": "Avbryt",
  "CART_ARTICLE_SKU_TOTAL": "{{article}} artikler",
  "CLEAR_ALL": "Tøm alle",
  "CLOSE": "Lukk",
  "CODE": "Kode",
  "COLOR": "Farge",
  "COMMENTS": "Kommentarer",
  "COMPANY_CITY": "Postadresse (poststed)",
  "COMPANY_DELIVERY_ADDRESS": "Leveringsadresse",
  "COMPANY_DELIVERY_CITY": "Leveringsadresse (Sted)",
  "COMPANY_DELIVERY_COUNTRY": "Leveringsadresse (Land)",
  "COMPANY_DELIVERY_ZIP_CODE": "Leveringsadresse (postnummer)",
  "COMPANY_EMAIL_CONFIRMATION": "Epostadresse (ordrebekreftelse)",
  "COMPANY_EMAIL_INVOICE": "Epostadresse (faktura)",
  "COMPANY_ORG_NAME": "Organisasjonsnummer",
  "COMPANY_PHONE": "Telefonnummer (firma)",
  "COMPANY_PREFERED_CURRENCY": "Ønsket handelsvaluta (NOK / Euro)",
  "COMPANY_ZIP_CODE": "Postadresse (postnummer)",
  "COMPLIANCES": "Sammensetning",
  "COMPOUND": "Sammensetning",
  "CONCEPT": "Konsept",
  "CONCEPTS": "Konsepter",
  "CONCEPT_ROppgi_NAME": "Konsept sortimentsnavn",
  "CONCEPT_TEMPLATE": "Konseptmal",
  "CONCEPT_TEMPLATES": "Konseptmaler",
  "CONDITIONAL_ON_FIELD": "Vilkårstyrt felt",
  "CONFIRM": "Bekrefte",
  "CONFIRM_DELETE_MESSAGE": "Dette er en permanent handling. Bekreft at du fortsatt vil slette.",
  "CONFIRM_PASSWORD": "Bekreft passord",
  "CONTACT_TITLE": "Kontaktperson (tittel / posisjon)",
  "CONTINUE": "Fortsett",
  "COOLING": "Kjøling",
  "COUNTRY_OF_ORIGIN": "Opprinnelsesland",
  "CREATE_ACCOUNT_FOR_NEW_CUSTOMER": "Opprett ny konto for eksisterende kunde",
  "CREATE_ASSORTMENT_TEMPLATE": "Opprett sortimentsmal",
  "CREATE_CONCEPT": "Opprett konsept",
  "CREATE_CONCEPT_TEMPLATE": "Opprett konseptmal",
  "CREATE_FIELD_DISPLAY_TEMPLATE": "Opprett mal",
  "CREATE_METADATA": "Opprett ny metadata",
  "CREATE_METADATA_COLLECTION": "Opprett ny metadata-kategori",
  "CREATE_NEW_FIELD": "Opprett felt",
  "CREATE_ROLE": "Opprett rolle",
  "CREATE_USER": "Opprett bruker",
  "CREATE_USER_ASSOCIATE_TO": "Opprett bruker tilhørende",
  "CURRENT": "Strøm",
  "CURRENT_230": "Strøm 230V",
  "CURRENT_400": "Strøm 400V",
  "CURRENT_690": "Strøm 690V",
  "CURRENT_CUSTOMER_BLOCKED": "Nåværende kunde er blokkert for å opprette bruker. Vennligst kontakt oss for mer informasjon.",
  "CUSTOMER_DASHBOARD": "Dashboard",
  "DB_LEVEL": "Støy dB",
  "DEGREE_OF_PROTECTION": "Kapslingsgrad",
  "DELETE": "Slette",
  "DELIVERY_ADDRESS_TAB": "Alternativ leveringsadr",
  "DELIVERY_NOTE": "Pakkseddel",
  "DESCRIPTION": "Beskrivelse",
  "DESELECT_ALL": "Opphev alle",
  "DESELECT_ALL_FILTERED_ITEMS": "Opphev utvalt",
  "DESELECT_ALL_ITEMS": "Opphev alle",
  "DIMENSIONS": "Dimensjoner",
  "DIMENSION_WHD_MM": "BxHxDmm",
  "DISPLAY_AS_FILTER": "Vis som filter",
  "DISPLAY_BATCH_PREFIX": "Vis prefiks",
  "DISPLAY_IN_LIST": "Vise i listen",
  "DISPLAY_TYPE": "Type display",
  "DOCUMENT": "Dokument",
  "DOCUMENTS": "Dokumenter",
  "DOCUMENT_TYPE": "Dokumenttype",
  "DOWNLOAD_PDF": "Last ned PDF",
  "EDIT": "Endre",
  "EFFECT": "kW",
  "EFFECT_PERCENT": "Effekt %",
  "EMAIL": "Email",
  "EMPTY": "Tom",
  "ENCLOSURE_SIZE": "Kapslings størrelse",
  "ENGINE_TYPE": "For motor",
  "ENTER_COMMENTS": "Oppgi kommentarer",
  "ENTER_COMPANY_CITY": "Oppgi postadresse (poststed)",
  "ENTER_COMPANY_DELIVERY_ADDRESS": "Oppgi leveringsadresse",
  "ENTER_COMPANY_DELIVERY_CITY": "Oppgi leveringsadresse (Sted)",
  "ENTER_COMPANY_DELIVERY_COUNTRY": "Oppgi leveringsadresse (Land)",
  "ENTER_COMPANY_DELIVERY_ZIP_CODE": "Oppgi leveringsadresse (postnummer)",
  "ENTER_COMPANY_EMAIL_CONFIRMATION": "Oppgi epostadresse (ordrebekreftelse)",
  "ENTER_COMPANY_EMAIL_INVOICE": "Oppgi epostadresse (faktura)",
  "ENTER_COMPANY_ORG_NAME": "Oppgi organisasjonsnummer",
  "ENTER_COMPANY_PHONE": "Oppgi telefonnummer (firma)",
  "ENTER_COMPANY_PREFERED_CURRENCY": "Oppgi ønsket handelsvaluta (NOK / Euro)",
  "ENTER_COMPANY_WEBSITE": "Oppgi hjemmeside",
  "ENTER_COMPANY_ZIP_CODE": "Oppgi postadresse (postnummer)",
  "ENTER_CONTACT_TITLE": "Oppgi kontaktperson (tittel / posisjon)",
  "ENTER_COUNTRY": "Oppgi land",
  "ERROR_MESSAGE": "Feilmelding",
  "ESTIMATED_DELIVERY_DATE": "Forventet sendingsdato",
  "EXISTING_CUSTOMER_NEED_ACCOUNT": "Eksisterende kunde og trenger en konto, klikk her",
  "EXPIRED_PRODUCTS": "Utgångna produkter",
  "EXPORT": "Eksporter",
  "EXPORT_EXCEL": "Eksporter Excel",
  "EXPORT_INCLUDED_ITEMS": "Eksporter inkluderte produkter",
  "EXTRA_PRODUCTS": "Eget produktsortiment",
  "FASTENING_BOLT": "Festebolt",
  "FEED_RATE": "Matefrekvens",
  "FEE_ADDED_LOT_SIZE": "Gebyr tilkommer (partistørrelse: {{value}})",
  "FIELD": "Felt",
  "FIELDBUS1": "Feltbus 1",
  "FIELDBUS2": "Feltbus 2",
  "FIELDS": "Felt",
  "FIELD_DEFINITIONS": "Feltdefinisjon",
  "FIELD_DISPLAY_TEMPLATE": "Visningsmal",
  "FIELD_DISPLAY_TEMPLATES": "Visningsmaler",
  "FIELD_NAME": "Feltnavn",
  "FILENAME": "Filnavn",
  "FILE_COULD_NOT_BE_UPLOADED": "Filen kunne ikke lastes opp",
  "FILE_NAME": "Filnavn",
  "FILE_NOT_FOUND": "Fil ikke funnet",
  "FILTERS": "Filter",
  "FLOppgi_DIAMETER": "Flensdiameter",
  "FORM_ERROR_MAX_LENGTH": "Maks {{requiredLength}} sifre. Faktisk lengde {{actualLength}}",
  "FREQUENCY": "Hz",
  "FRONT_TYPE": "Type Front",
  "GENERATE_EXCEL": "Generer excel",
  "GROSS_WEIGHT": "Bruttovikt",
  "HARD": "Hard",
  "HARDNESS": "Hardhet",
  "HAS_IMAGE": "Har bilde",
  "HAS_ONE_OF_VALUES": "Har ett av følgende verdier",
  "HELP": "Hjelp",
  "HELP_EFFECT": "Hjelp Effekt",
  "HELP_ENCLOSURE": "Hjelp kapsling",
  "HELP_TENSION": "Hjelp Spenning",
  "HOLE_DIAMETER": "Hulldiameter",
  "HOLE_PATTERN": "Hullbilde",
  "HOME": "Hjem",
  "HUB_DIAMETER": "Nav diameter",
  "IEC": "IE klasse",
  "IE_CLASS": "IEC",
  "IMAGES": "Bilder",
  "IMPORTS": "Importer",
  "IMPORT_EXCEL": "Importer excel",
  "IMPORT_EXPORT": "Import / eksport",
  "IMPORT_FAILED": "Importering mislykket",
  "IMPORT_FROM_EXCEL": "Importer fra excel",
  "IMPORT_IMAGE(S)": "Importer bilder",
  "IMPORT_SUCCESSFUL": "Importering fullført",
  "INCLUDED_ITEMS": "Inkluderte produkter",
  "INCLUDED_PRODUCTS": "Inkluderte produkter",
  "INCLUDE_PRODUCTS_CONCEPTS": "Inkluder:",
  "INIT_DEFAULT_FIELDS": "Initsier standrad felt",
  "INPUT_TYPE": "Innmatningsfelt",
  "INSERT_MATERIAL": "Innlegg",
  "INSIDE_DIAMETER": "Innvendig diameter",
  "INSIDE_MATERIAL": "Innvendig materiale",
  "INVOICES": "Faktura",
  "IN_STOCK_VALUE": "{{value}} STK",
  "IP_RATING": "IP grad",
  "ITEM_ID": "Produkt ID",
  "ITEM_NAME": "Produktnavn",
  "ITEM_UNIQUE_METADATA": "Artikelunik metadata",
  "LABEL": "Tittel",
  "LABEL_TRANSLATE_KEY": "Oversettingsnøkkel",
  "LANGUAGE_EN": "Engelsk",
  "LANGUAGE_SE": "Svensk",
  "LEFT_TO_PAY": "",
  "LENGTH_MM": "Lengde (mm)",
  "LIGHTOPENING_MM": "Lysåpning (mm)",
  "LIGHT_SOURCE_INFORMATION": "Lyskilde",
  "LOAD_CAPACITY": "Bæreevne kg",
  "LOAD_MORE": "Vis ytterligere {{batchSize}}",
  "LOAD_ORDER_CART_CONFIRMATION": "Last handlevogn?",
  "LOGOUT": "Logg ut",
  "MAIN_CATEGORY": "Type",
  "MAIN_FILTER": "Nettfilter",
  "MASTER_CATEGORY": "Hovedkategori",
  "MATCHES_PRODUCT_IDS": "Matchende produkter",
  "MATERIAL": "Materiale",
  "MAXDIAMETER_MM": "Diameter, maks (mm)",
  "MAX_CABLE_DIAMETER": "Maks Kabeldiamter",
  "METADATA": "Metadata",
  "METADATA_COLLECTION": "Metadata kategori",
  "METADATA_OPTIONS": "Metadata alternativer",
  "MINDIAMETER_MM": "Diameter, min (mm)",
  "MULTI_SELECT": "Flervalg",
  "NAME": "Navn",
  "NET_PRICE": "netto",
  "NET_WEIGHT": "Nettovekt",
  "NEW_CUSTOMER_NEED_ACCOUNT": "Ny kunde og trenger en konto, klikk her",
  "NEXT": "Neste",
  "NOT_ENOUGH_AVAILABILITY": "Ønsket kvantum ikke tilgjengelig",
  "NOT_IN_STOCK": "Ikke på lager",
  "NOT_VALID_LENGTH": "Numerisk {{length}} siffer uten punktum eller mellomrom.",
  "NO_ACTION_SELECT_ACTION": "Velg Handling",
  "NO_CUSTOM_FELDS_EXIST_FOR_EXPORT": "Det finnes ingen passende valg for valgte importtype, bare basisfeltene blir eksportert",
  "NO_RESULTS_FOUND": "Ingen treffende kunder vises",
  "OFFSET": "Offset",
  "OH_DELLEVTILLATEN_0": "Ja",
  "OH_DELLEVTILLATEN_10": "Nei",
  "OH_EDITEXT": "Melding til kundesenter",
  "OH_GODSMARKE3": "E-post",
  "OH_GODSMARKE4": "Telefon",
  "OH_KUNDBESTNR": "Bestillingsnummer",
  "OH_TA_MAILNOTIFIED": "E-post leveringsinformasjon",
  "OLD_PRODUCTS": "Utgående produkter",
  "OTTO_CONTACT": "Namn (kontaktperson)",
  "OTTO_COUNTRY": "Postadresse (land)",
  "OTTO_EMAIL": "Epostadresse (kontaktperson)",
  "OTTO_ENTER_COUNTRY": "Oppgi postadresse (land)",
  "OTTO_ENTER_EMAIL": "Oppgi epostadresse (kontaktperson)",
  "OTTO_ENTER_PHONE": "Oppgi telefonnummer (kontaktperson)",
  "OTTO_FREIGHT_COST": "Ordre på mindre enn kr 500 vil bli belastet med kr 250,- for pakking og emballasje.",
  "OTTO_OLSEN_DELIVERY_DATE_MAY_CHANGE": "Leveringsdato kan bli endret",
  "OTTO_OLSEN_DELIVERY_DATE_MAY_CHOppgi": "Leveringsdatoer kan endres med forskjellige leveringsmetoder",
  "OTTO_OLSEN_ORDER": "Ordre",
  "OTTO_OLSEN_PICK_TWO_HOURS_CONFIRMATION": "Varene kan tidligst hentes to timer etter mottatt ordrebekreftelse",
  "OTTO_OLSEN_QUOTATION": "Tilbud",
  "OTTO_OLSEN_DELIVERYINFO": "Grunnet en global svært krevende leveringssituasjon kan vi dessverre ikke garantere for salgspriser på varer som ikke er på lager",
  "OTTO_PHONE": "Telefonnummer (kontaktperson)",
  "OUR_REFERENCE": "OO referanse",
  "OUTPUT_FREQUENCY": "Utgangsfrekvens",
  "OUTSIDE_DIAMETER": "Utvendig diameter",
  "OUTSIDE_MATERIAL": "Utvendig materiale",
  "OVERLOAD2_5S": "Overlast 2,5s",
  "OVERLOAD4S": "Overlast 4s",
  "OVERLOAD60S": "Ut spenning",
  "OVERLOAD6S": "Overlast 60s",
  "PARTIAL_DELIVERIES_ASAP": "Dellevering",
  "PART_DELIVERY": "Delvis leveranse",
  "PART_DELIVERY_MESSAGE": "Produktene blir delvis levert, frakt vil bli belastet for hver levering.",
  "PASSWORD": "Passord",
  "PASSWORD_HAS_BEEN_RESET": "Passord har blitt tilbakestilt",
  "PASSWORD_MISMATCH": "Passord er ikke likt",
  "PIM_COLLECTION": "PIM database",
  "PIM_ITEM": "PIM produkt",
  "PIM_ITEM_CATEGORY": "Artikkelkategori",
  "PIM_ITEM_CONCEPT_SAVED": "Konsept lagret",
  "PIM_ITEM_COUNTRY_OF_ORIGIN": "Opprinnelsesland",
  "PIM_ITEM_METADATA": "Artikkelunik metadata",
  "PIM_ITEM_SAVED": "Produkt lagret",
  "PLATE_SIZE": "Platestørrelse",
  "POLES": "Poler",
  "POST_ADDRESS": "Postadresse",
  "POWER_FACTOR": "cosφ",
  "PO_NUMBER": "Bestillingsnummer",
  "PRESSURE": "Trykk",
  "PREVIOUS": "Forrige",
  "PROCESS_CONTROLS": "Prosess kontroller",
  "PRODUCTS": "Produkter",
  "PRODUCT_FIELDS": "Produkt felter",
  "PRODUCT_LIST_FILTER_TOTAL": "Totalt filtrert",
  "PRODUCT_LIST_TOTAL": "Totalt",
  "PROGRAMMABLE_INPUTS": "Programerbare inngOppgir",
  "PROGRAMMABLE_OUTPUTS": "Programerbare utgOppgir",
  "PROPERTIES_ARARTBESKR": "Varebeskrivelse",
  "PROPERTIES_ARARTBESKRSPEC": "Varespesifikasjon",
  "PROPERTIES_ARARTNR": "Varenr",
  "PROPERTIES_AREXTRA1": "Søketekst",
  "PROPERTIES_VGVARUGRUPPBESKR": "Varegruppe",
  "PUSH_DELIVERY_DATE": "Samlet levering",
  "QUANTITY_IN_ORDER": "Antal",
  "QUERY_FIELD": "Søkefelt",
  "RATED_CURRENT": "Merkestrøm",
  "READ": "Les",
  "READ_MORE_AND_ORDER": "Les mer og bestill",
  "READ_ONLY": "Kun lesing",
  "RELATED_PRODUCTS": "Relaterte produkter",
  "REMOVE_ASSORTMENT": "Ta bort sortiment",
  "REMOVE_FROM_FAVOURITE_LIST": "Fjern fra favorittlisten",
  "REQUEST_ACCOUNT": "Søknad om kundeforhold",
  "RESET_PASSWORD": "Tilbakestill passord",
  "RESPONSIBLE": "Ansvarlig",
  "ROLE": "Rolle",
  "ROLES": "Roller",
  "RPM": "Hastighet",
  "SAFETY": "Sikkerhet",
  "SAFETY_CLASS": "Beskyttelse",
  "SALES_AMOUNT_PER_MONTH": "Kjøp denne måned",
  "SALES_AMOUNT_PER_YEAR": "Kjøp til dato",
  "SALES_INFORMATION": "Salgsinformasjon",
  "SALES_PER_YEAR": "Kjøp totalt",
  "SAVE": "Lagre",
  "SAVE_CART_TO_EXCEL": "Lagre handlekurven i Excel",
  "SEARCH": "Søk",
  "SEARCH_ARTBESKR": "Søk varebeskrivelse",
  "SEARCH_ARTBESKRSPEC": "Søk varespesifikasjon",
  "SEARCH_ARTNR": "Søk varenummer",
  "SEARCH_CONCEPT": "Søk for å velge konsept",
  "SEARCH_EXTRA1": "Søketekst",
  "SEARCH_FOR_ITEMS": "Søk artikler",
  "SEARCH_PRODUCT": "Søk produkter",
  "SELECT": "Velg",
  "SELECTED_FILTERS": "Velg filter",
  "SELECTED_ITEM_COUNT": "Valgte artikler",
  "SELECT_ALL": "Velg alle",
  "SELECT_ALL_FILTERED_ITEMS": "Velg utvalgte",
  "SELECT_ALL_ITEMS": "Velg alle",
  "SELECT_FIELDS_TO_EXPORT": "Velg felt for eksportering",
  "SELECT_FIELD_ORDER": "Velg feltrekkefølge",
  "SELECT_FILE": "Velg fil",
  "SELECT_IMPORT_TYPE": "Velg import-type",
  "SEND_PASSWORD_RESET": "Send nytt passord",
  "SERIES": "Serie",
  "SETTINGS": "Innstillinger",
  "SHORE": "Shore",
  "SOFT": "Soft",
  "STAINLESS": "Rustfri",
  "STANDARD": "Standard",
  "STOCK_STATUS": "{{stock}} {{unit}}",
  "SUBMIT": "Sende inn",
  "SUB_CATEGORY": "Underkategori",
  "SUCCESS": "Suksess",
  "SUGGEST_ID": "Foreslå ID",
  "SUPPLIER_NAME": "Leverandør",
  "TECHINCAL_CHARACTERISTICS_CATALOG": "Tegning",
  "TECHNICAL_INFORMATION": "Teknisk informasjon",
  "TEMPERATURE_ROppgi": "Temperaturområde",
  "TEMPLATE": "Mal",
  "TEMPLATE_NAME": "Malnavn",
  "TEXTS": "Texter",
  "THICKNESS_MM": "Tykkelse (mm)",
  "THIS_MONTH": "Denne måned",
  "TORQUE": "Nm",
  "TOWER_DIAMETER": "Tårndiameter",
  "TRACE_DIAMETER": "Diameter spor",
  "TRANSLATE_PROPERTY": "Oversettings egenskaper",
  "TRY_AGAIN": "Prøv igjen",
  "TUBE_LENGTH": "Standard slOppgilengde",
  "TURNING_RADIUS": "Svingradius",
  "TYPE": "Type",
  "TYPE_DESIGNATION": "Typebetegnelse",
  "UNSAVED_CHOppgiS_CONTENT": "Du har ulagrede endringer. Fortsetter du vil disse forsvinne",
  "UNSAVED_CHOppgiS_HEADING": "Ulagrede endringer",
  "UPLOADED_TO_PRODUCTS": "Last opp til følgende produkter",
  "UPLOAD_DOCUMENT(S)": "Last opp dokument",
  "UPLOAD_EXCEL": "Last opp XLSX",
  "UPLOAD_IMAGE(S)": "Last opp bilder",
  "USERNAME": "Brukernavn",
  "USERS": "Brukere",
  "VACUUM": "Vakum",
  "VALIDATION": "Validering",
  "VALUES": "Verdier",
  "VG_Q_RESPONSIBLE": "Produktansvarlig",
  "VG_VARUGRUPPBESKR": "Varegruppe",
  "VIEW": "View only",
  "VOLTAGE": "Spenning",
  "VOLTAGE_FEED": "Matespenning",
  "WEB_WIDTH": "Banebredde",
  "WEIGHT": "Egenvekt",
  "WEIGHT_PER_METER": "Vekt per meter",
  "WHEEL_AXIS": "Hjulaksel",
  "WHEEL_DIAMETER": "Hjuldiameter mm",
  "WHEEL_TRACK": "Hjulbane",
  "WIDTH": "Bredde",
  "WORKING_PRESSURE": "Arbeidstrykk Bar",
  "WRITE": "Skriv",
  "YOUR_REFERENCE": "Din referanse",
  "YOUR_REFERENCE_NO": "Bestillingsnummer",
  "BARCODE_SCANNER_QUICKORDER": "Barcode Scanner Quickorder",
  "AR_FIELDS": "Varefelt",
  "DELIVERY_DATE_CHANGED_DUE_TO_STOCK": "Leveringsdato endret i henhold til tilgjengelig lager",
  "ADD_ITEMS_TO_CART": "Legg Varer i handlekurv",
  "WHAT_DO_YOU_WANT_TO_DO": "Hva onsker du å gjore?",
  "SHOPPING_CART": "Handlekurv",
  "NUMBER_OF_SPECIES": "Antall art.",
  "CONFIRM_DELETE_SHOPPING_CART": "Er du sikker på at du vil slette folgende lagrede handlekurv",
  "QTY": "Ant.",
  "ARTNRLEV_ALREADY_EXISTS": "Valgt lev. varenummer finnes allerede",
  "TOOLTIP_VAREBESKRIVELSE": "Varebeskrivelse maksimalt 40 tegn. Denne beskrivelsen vises på pakkseddel og vareetikett.",
  "TOOLTIP_VARESPESIFIKASJON": "Varespesifikasjon maksimalt 30 tegn. Denne beskrivelsen vises ikke på pakkseddel og vareetikett.",
  "TOOLTIP_VAREBESKRIVELSE2": "Utfyllende teknisk beskrivelse av varen. Maksimalt 255 tegn.",
  "TOOLTIP_EXTRA1": "Søketekst for varen. Undersøk tilsvarende vare og følg samme syntax.",
  "TOOLTIP_VAREGRUPPEKODE": "Søk frem ønsket varegruppe. Søk på nummer eller beskrivelse.",
  "TOOLTIP_PRODUKTKLASSE": "Velg ønsket produktgruppe. Kun tilgjengelig for varegrupper med undergrupper.",
  "TOOLTIP_RABATTGRUPPE": "Søk frem ønsket rabattgruppe. Søk på nummer eller beskrivelse.",
  "TOOLTIP_ENHET": "Angi varens salgsenhet.",
  "TOOLTIP_ANTALL_DESIMALER": "Angi antall desimaler for varens salgskvantitet.",
  "TOOLTIP_ENHETSVEKT": "Angi varens egenvekt.",
  "TOOLTIP_HS_KODE": "Søk frem ønsket HS-kode. Søk på nummer eller beskrivelse.",
  "TOOLTIP_OPPRINNELSESLAND": "Velg opprinnelsesland til varen. Dette er landet hvor varen er produsert eller eventuelt gjennomgått vesentlige endringer.",
  "TOOLTIP_VARETYPE": "0 Kundevare – Usikker pris / på forespørsel\nDette er en kombinasjon av 1 «Kundevare» og 7 «Usikker pris / på forespørsel». Se under.\n\n1 Kundevare\nVarer som er unike for en bestemt kunde. Kundevare er ikke lagerført og kjøpes kun inn mot ordre. ForetaksNr/ID skal alltid være fylt inn og referere til kundens kundenummer. Alle kunder kan se sine «egne varer» i B2B.\n\n2 Kundevare med avtale i Landax\nVarer som er unike for en bestemt kunde. Varene er normalt lagerført i henhold til skriftlig avtale med kunde, dokumentert i Landax. ForetaksNr/ID skal alltid være fylt inn og referere til kundens kundenummer. Alle kunder kan se sine «egne varer» i B2B.\n\n3 Kundevare med muntlig\nVarer som er unike for en bestemt kunde. Varene er normalt lagerført etter muntlig avtale med kunde. ForetaksNr/ID skal alltid være fylt inn og referere til kundens kundenummer. Alle kunder kan se sine «egne varer» i B2B.\n\n7 Usikker pris / På forespørsel\nVarer som ikke har en fast pris og må pris-sjekkes ved salg. Dette kan være varer hvor volum markant påvirker prisen, generelt ustabilt prisbilde eller varer som forventes ikke å bli gjenbrukt i nærmeste fremtid og pris dermed ikke vil bli vedlikeholdt. Listepris i Jeeves settes per automatikk til 999.999,-\nKode 7 gjør varen automatisk til «pris på forespørsel» i B2B og kunde kan ikke se listepris i Jeeves.\n\n8 Normalvare – Ikke prisliste\nNormalvare er varer hvor vi har riktig listepris registrert i Jeeves og varen kan selges uten videre kontroll. Varer kodet med 8 «Normalvare – ikke prisliste» vil ikke bli tatt med ved utarbeidelse av kundeprislister.\n\n9 Normalvare – Prislister\nNormalvare er varer hvor vi har riktig listepris registrert i Jeeves og varen kan selges uten videre kontroll. Varer kodet med 9 «Normalvare – Prisliste» vil bli tatt med ved utarbeidelse av kundeprislister.",
  "TOOLTIP_AVTALEKUNDE": "Søk frem ønsket avtalekunde. Søk på kundenavnnavn eller kundernummer. Feltet benyttes når vare er kundeunik (kundevare).",
  "TOOLTIP_LEVERANDOR": "Søk frem ønsket leverandør. Søk på leverandørnavn eller leverandørnummer.",
  "TOOLTIP_LEVVARENUMMER": "Leverandøren sitt varenummer. Maksimalt 30 tegn. Feltet KAN inneholde mellomrom, men feltet kan ikke starte eller slutte med mellomrom.",
  "TOOLTIP_LEVVARENAVN": "Leverandøren sin varebeskrivelse. Maksimalt 75 tegn (skrives ut på en linje på PO).",
  "TOOLTIP_LEVERANDOR_VARESPESIFIKASJON": "Utfyllende teknisk beskrivelse av varen. Maksimalt 255 tegn. Informasjon skrives ut på PO.",
  "TOOLTIP_INNPRIS_VALUTA": "Innkjøpspris hos leverandør i lokal valuta.",
  "TOOLTIP_RABATT": "Eventuell rabatt i % fra leverandør.",
  "TOOLTIP_VALUTA": "Angi varens innkjøpsvaluta.",
  "TOOLTIP_MARKUP": "Markup-faktor hentes fra Jeeves og er koblet mot rabattklasse. Markup x kostpris = salgspris.",
  "TOOLTIP_PRISENHETSFAKTOR": "Angi pris enhetsfaktor dersom pris fra leverandør avviker fra pris per stk. (Eksempel. Dersom leverandør angir pris for vare per 100 stk. berikes feltet med 100).",
  "TOOLTIP_INPRIS_NETTO": "Kalkulert innkjøpspris i NOK. Kalkulert gjennom Innpris (valuta) x rabatt (dersom angitt) x valutakurs i Jeeves.",
  "TOOLTIP_LISTEPRIS": "Kalkulert listepris i NOK. Kalkulert gjennom Innpris netto (nok) x markup-faktor tilhørende rabattgruppe i Jeeves.",
  "TOOLTIP_PAKKESTORRELSE_INNKJOP": "Angir eventuell pakkestørrelse hos leverandør. (X antall enheter i en forpakning). Innkjøpsvolum kan ikke avvike fra forpakningsantall x heltall. ",
  "TOOLTIP_LEDETID": "Samlet antall dager fra varen blir bestilt til forventet forsendelse fra leverandør",
  "TOOLTIP_PAKKESTORRELSE": "Pakkestørrelse angir antall enheter det er i en pakke, rull, parti eller liknende. Ved ordregistrering i Jeeves vil ordreantall annet enn pakkestørrelse generere advarsel. Advarsel kan overstyres.",
  "TOOLTIP_LAGTYP": "Lagerført vare: Standard lagerført vare uten batch-håndtering.\n\nBatchnummer ved innlevering: Varen stopper hos varemottak og avventer at varen merkes med batch-nummer fra vår leverandør. \n\n Batchnummer og dato ved innlevering: Varen stopper hos varemottak og avventer at varen merkes med batch-nummer fra vår leverandør. + varen må berikes med antall dager holdbarhet.",
  "TOOLTIP_SQSHELFLIFEINDAYS": "Angi varens holdbarhet i dager.",
  "TOOLTIP_QJISCLASSID": "Angi om sertifikat er påkrevd for denne varen.",
  "FREE_FREIGHT_AGREEMENT": "Ordren sendes fraktfritt i henhold til avtale",
  "FREE_FREIGHT_AFTER": "Frakt: {{name}} har avtale om gratis frakt på ordrer over {{value}}",
  "MISSING_FREE_FREIGHT": ",-. Du mangler {{value}}, for gratis frakt",
  "SCAN_NEW": "Skann ny",
  "MENU": "Meny",
  "LOGGED_AS": "Logget som",
  "PRODUCT_NUMBER": "Produktnummer",
  "PRODUCT_NAME": "Produktnavn",
  "PRODUCT_FEATURES": "Produktfunksjoner",
  "CLEAN_CART": "Tøm handlekurv",
  "NUMBER_ARTICLES": "Antall artikler",
  "CART_CONTENT": "Innhold i handlekurv",
  "SCAN_BARCODE_WITH_CAMERA": "Skann strekkoden med kameraet",
  "OTTO_OLSEN_ORDER_DETAIS": "Otto Olsen - Ordredetaljer for ordre nr. {{order}}. Kundens bestillingsnummer: {{customerOrderId}}",
  "CUSTOMER_ORDER_ID": "Kundens best.nr",
  "DEVIVERED": "Levert",
  "EXPECTED_DELIVERY_DATE": "FORVENTET LEVERANSEDATO",
  "OTTO_OLSEN_ORDER_OVERVIEW": "Otto Olsen - Ordreoversikt",
  "CREATE_PRODUCT": "Opprett produkt",
  "MARKUP_BELOW_TWO": "Markup er under 2!",
  "TOOLTIP_NOTE": "Her kan du legge til tilleggsinformasjon.",
  "TOOLTIP_COMMENT": "Denne produktinformasjonen sendes kun via e-post og vil ikke være tilgjengelig på produktsiden.",
  "NOTES": "Notater",
  "VIEWER_DESCRIPTION": "Naviger, søk og se priser",
  "USER_DESCRIPTION": "Bestill, naviger, søk og se priser",
  "NOTES_TO_PRODUCT_RESPONSIBLE": "Kommentar til produktansvarlig",
  "GO_TO_PRODUCTS": "Gå til produkt",
  "FORM_ERROR_NO_SPACE": "Feltet skal ikke inneholde et mellomrom.",
  "SCANNER_CONFIGS": "Skannerkonfigurasjoner",
  "TIME_SCANNER": "Tid lest ms",
  "SCAN_PRODUCTS_IN_CART": "Skann produkter som allerede er i handlekurven",
  "PROHIBITED_CHARACTERS_VALIDATOR": "Følgende tegn er forbudt: ‒ — ’ ” ¼ ½ ¾",
  "3D_MODEL": "3D-modell",
  "DOWNLOAD_3D_MODEL": "Last ned 3D-modell",
  "FORMAT": "Format",
  "VIEW_PRODUCT_AS_3D_MODEL": "Vis produkt som 3D-modell",
  "VIEW_PRODUCT_IMAGES": "Vis produktbilder",
  "3D_MODEL_VIEWER": "3D-modellviser",
  "Y_AXIS_UP_VECTOR": "Sett Y-aksen som oppvektor",
  "Z_AXIS_UP_VECTOR": "Sett Z-aksen som oppvektor",
  "FLIP_UP_VECTOR": "Vend opp vektor",
  "FIXED_UP_VECTOR": "Fikset vektor",
  "FREE_ORBIT": "Fri bane",
  "PERSPECTIVE_CAMERA": "Perspektivkamera",
  "ORTHOGRAPHIC_CAMERA": "Ortografisk kamera",
  "BACK_TO_ORDER": "Tilbake til ordreoversikt",
  "FORM_WARNING_ENABLE_EDIT_DELIVERY_ADDRESS": "Leveringsdato kan bli endret",
  "FORM_DANGER_ENABLE_EDIT_DELIVERY_ADDRESS": "Det er ikke tillatt å angi en postboks som en leveringsadresse",
  "ORDER_ANALYTICS_BETA": "Ordreanalyse (beta)",
  "SALES_DASHBOARD_BETA": "Salgsoversikt (beta)",
  "MEETIGN_AMOUNT": "Møtebeløp",
  "TOTAL_LAST_MONTH": "Totalt siste måned",
  "TOTAL_THIS_MONTH": "Totalt denne måneden",
  "COMPLETED_THIS_MONTH": "Fullført denne måneden",
  "COMPLETED_LAST_MONTH": "Fullført forrige måned",
  "MEETINGS_TARGET": "Fremgang denne måneden",
  "MEETINGS_LAST_MONTH_TARGET": "Fremgang forrige måned",
  "CONVERTED_IN_ORDER_THIS_MONTH": "Faktiske månedsmøter genererte rekkefølge",
  "CONVERTED_IN_ORDER_LAST_MONTH": "Forrige måneds møter genererte bestilling",
  "SEE_PRICE_DETAIL": "Vis prisdetaljer",
  "HIDE_PRICE_DETAIL": "Skjul prisdetaljer",
  "PACKAGING": "Forpakning",
  "MIN.PURCHASE": "Minstekjøp",
  "CUTTING_FEE": "Kapptillegg",
  "CUTTING_FEE_TEXT": "- tilkommer ved kjøp av andre mengder enn hele forpakninger",
  "IN_CUTTING_FEE": "i kapptillegg",
  "INCL": "Inkl.",
  "REC_PRICE": "Veil.price",
  "HIDE_EDIT_PIM_LINKS": "Skjul rediger PIM-koblinger",
  "SAVED_CARTS_BY": "Av",
  "SAVED_CARTS_AT": "kl",
  "EXIT_SCANNER_MODE": "Avslutt skannermodus",
  "BARCODE_SCANNER": "Barcode Scanner",
  "DELIVERY_LOCATION_MENU": "Velg kunde/avdeling",
  "NETPRICE": "Nettopris",
  "NUMBER_COMMA_VALIDATION": "Ugyldige tegn er angitt. Bare tall og komma er tillatt.",
  "OVERDUE_INVOICES": "Forfalte fakturaer",
  "OVERDUE": "Forfalt",
  "LIMIT_ORDER_ERROR": "Det er dessverre ikke mulig å bestille mer enn {{stock}} for dette produktet. Kontakt Otto Olsen om du har behov utover dette antallet.",
  "ACCESS_FORBIDDEN_ERROR": "Du har ikke tilgang til å vise dette produktet.",
  "NO_ACCESS": "Ingen tilgang",
  "VARESPESIFIKASJON": "Varespesifikasjon",
  "STOCK_FILTER": "Lager",
  "FILTER_IN_STOCK": "Vis kun varer på lager",
  "WEBSHOP_OTTO_OLSEN": "Otto Olsen B2B",
  "DEFAULT_SCANNER": "Standard skanner",
  "NEW_SCANNER": "Ny skanner",
  "CALCULATE_PRICE": "Beregn pris",
  "PACK_SIZE_IS": "Pakkestørrelsen er",
  "YOU_WERE_NOT_LOGGED_IN": "Du ble ikke logget inn",
  "YOUR_USER_IS_NOT_CONNECTED_TO_A_CUSTOMER": "Din bruker, {{user}}, er ikke tilkoblet en kunde i systemet.",
  "PLEAS_CONTACT_OTTO_OLSEN_AT": "Ta kontakt med Otto Olsen pa post@oo.no eller 63 89 0800 for hjelp.",
  "PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION": "Bestillinger gjennom nettbutikken vil bli levert ut fra beholdning i {{Warehouse}}.",
  "PRODUCT_DETAILS_AVAILABILITY_WAREHOUSE_DESCRIPTION_2": "For a bestille denne varen fra annen lokasjon krever at du tar kontakt med Otto Olsen direkte.",
  "CHANGES_MAY_OCCUR": "Endringer kan forekomme",
  "STOCK_STATUS_LABEL": "Lagerstatus",
  "LEAVE_CART_WARNING_TITLE": "Forlat vogn",
  "LEAVE_CART_WARNING_TEXT": "Er du sikker på at du vil forlate utsjekkingsprosessen nå? Alle data knyttet til ordreinnstillinger og datoer vil bli tilbakestilt. (Produkter, mengder, priser vil ikke bli endret)",
  "ONLY_SOME_IN_STOCK": "Vi har kun {{currentStock}} av {{qty}} på lager. Kan levere {{qty}} stk {{date}}",
  "WE_DONT_HAVE_ENOUGH_ITEMS": "Vi har ikke nok varer",
  "WE_ONLY_HAVE_OF_THIS_ITEM": "Vi har kun {{currentStock}} stk av denne varen du har skannet.",
  "DO_YOU_WANT_TO_REDUCE_FROM": "Ønsker du å redusere fra <b>{{qty}}</b> til <b>{{currentStock}}</b> {{unit}}.",
  "DELETE_THE_ITEM_LINE_IN_QUESTION": "Slett den aktuelle varelinjen",
  "KEEP_LEADS_TO_LONGER_DELIVERY_TIME": "Behold {{qty}} stk (fører til lengre leveringstid)",
  "REDUCE_THE_NUMBER_TO_PCS": "Reduser antallet til {{currentStock}} {{unit}}.",
  "REMOVE_LINE": "Slett linje",
  "KEEP_PCS": "Beholde {{qty}} {{unit}}",
  "FOR_INFORMATION_ONLY": "Kun til informasjon",
  "WE_ARE_OUT_OF_STOCK": "Vi er tomme for varen",
  "UNFORTUNATELY_WE_ARE_OUT_OF_STOCK": "Vi er dessverre tomme for denne varen på lager. Hva ønsker du å gjøre?",
  "LEAVE_IN_THE_ORDER": "La det stå {{qty}} {{unit}} i bestilling (fører til lengre lev.tid)",
  "THIS_ITEM_HAS_BEEN_DISCONTINUED": "Denne varen er utgått",
  "THIS_ITEM_HAS_BEEN_DISCONTINUED_BUT_HAS_RECEIVED_REPLACEMENT_PRODUCT": "Denne varen er dessverre utgått fra vårt sortiment, men har fått en erstatningsvare (Erstatningsvaren har artikkelnummer: {{id}})",
  "CONTACT_OTTO_OLSEN_REGARD_TO_FUTURE_SCANS": "Ta kontakt med Otto Olsen for å avklare hva {{customerName}} ønsker å gjøre med tanke på fremtidige skanninger av denne varen/strekkoden",
  "STOCK_STATUS_REPLACEMENT_ITEM": "Lagerstatus erstatningsvare",
  "UNFORTUNATELY_THIS_ITEM_HAS_BEEN_DISCONTINUED": "Denne varen er dessverre utgått fra vårt sortiment.",
  "ORDER_CHANNEL": "Bestillingskanal",
  "UPLOAD_IMPORT_LINESHEET": "Last opp",
  "ENABLE_WAY_OF_DELIVERY_MOBILE_CHECKOUT": "Aktiver leveringsmåte",
  "BLOCK_ORDER_AGREEMENT_ID": "Avtalenummer",
  "BLOCK_ORDER_AGREEMENT_DATE": "Avtal dato",
  "BLOCK_ORDER_AGREEMENT_VALID_TO_DATE": "Avtale tom. dato",
  "OO_BOA_VARENUMMER": "Varenummer",
  "OO_BOA_BESKRIVELSE": "Beskrivelse",
  "OO_BOA_LAGERSTATUS": "Lagerstatus",
  "OO_BOA_PROGNOSE": "Prognose",
  "OO_BOA_ANTALL": "Antall",
  "OO_BOA_AVTALEVERDI": "Avtaleverdi",
  "BLOCK_ORDER_AGREEMENTS": "Blokker ordreavtaler",
  "CREATE_BLOCK_ORDER_AGREEMENTS": "Opprett avtale om blokkbestilling",
  "DATE_OF_REMINDER": "Dato for påminnelse",
  "THEIR_REFERENCE": "Deres referanse",
  "VALID_BLANK_DATE": "Gyldig Tom. dato.",
  "SUPER_OFFICE_AGREEMENT_NUMBER": "Super office avtalenummer",
  "CUSTOMER_ORDER_NUMBER": "Kundebestillingsnummer",
  "BLOCK_ORDER_LINES": "Blokkér ordrelinjer",
  "AGREEMENT_RETURN_QTY": "Avtale returantall",
  "AGREEMENT_FORECAST": "Avtaleprognose",
  "SALE_PRICE": "Utsalgspris",
  "OFFER_STATUS": "Tilbudsstatus",
  "BLOCK_ORDER_CREATION_FAILED": "Oppretting av blokkbestilling mislyktes",
  "REMOVE_ROW": "Fjern rad",
  "BLOCK_ORDER_CREATED": "Blokkbestillingsavtale opprettet",
  "BLOCK_ORDER_CONTRACT": "Hovedavtale",
  "BLOCK_ORDER_STATUS": "Status",
  "BLOCK_ORDER_CONTACT": "Kontakt",
  "BLOCK_ORDER_REF_NUMBER": "Referansenummer"
}
