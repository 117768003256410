import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import { FormUtilService, OptionsList, OptionsListMulti } from 'gung-common';
import { SearchRequest } from 'gung-list';
import {
  Assortment,
  AuthService,
  CheckoutObject,
  CustomerService,
  GungModalService,
  OrderRow,
  OrderSubmitService,
  Product,
  ProductService
} from 'gung-standard';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  EMPTY,
  filter,
  first,
  forkJoin,
  map,
  Observable,
  of,
  OperatorFunction,
  switchMap
} from 'rxjs';

@Component({
  selector: 'otto-olsen-block-order-agreement-create',
  templateUrl: './otto-olsen-block-order-agreement-create.component.html',
  styleUrl: './otto-olsen-block-order-agreement-create.component.scss'
})
export class OttoOlsenBlockOrderAgreementCreateComponent implements OnInit {
  public form: FormGroup;
  assortment: string;
  customerId: string;

  public productSelect: Product[] = [];
  saveClick = false;
  rows = [];

  loading = false;
  public customerOptionsList: OptionsListMulti[] = [];

  dateValues: OptionsList[] = [
    {id:'6', name: this.translateService.instant('6_MONTHS')},
    {id:'12', name: this.translateService.instant('12_MONTHS')},
    {id:'18', name: this.translateService.instant('18_MONTHS')},
    {id:'24', name: this.translateService.instant('24_MONTHS')},
  ]

  search: OperatorFunction<string, readonly { id; name }[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      switchMap(term => {
        const searchRequest: SearchRequest = {
          terms: [...term.split(' ')],
          skip: 0,
          limit: 10,
          customerId: this.customerId,
          assortment: this.assortment || 'sROT'
        };
        return forkJoin([of(term), this.productService.getPagedProducts(searchRequest)]);
      }),
      map(([term, products]) =>
        products.items./* filter(product => new RegExp(term, 'mi').test(product.name)). */ slice(0, 10)
      )
    );

  formatter = (product: Product) => product.id + ' - ' + product.name;

  constructor(
    protected formBuilder: FormBuilder,
    protected formUtilService: FormUtilService,
    protected productService: ProductService,
    protected orderSubmitService: OrderSubmitService,
    protected gungModalService: GungModalService,
    protected router: Router,
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected customerService: CustomerService
  ) {}

  ngOnInit(): void {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        this.assortment = user.assortment;
      });

    this.customerService
      .getCustomers()
      .pipe(first())
      .subscribe(customers => {
        this.customerOptionsList = customers.map(customer => {
          return {
            id: customer.id,
            name: customer.name,
            selected: false
          };
        });
      });

    this.initForm();
    this.addRow();
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      OffBeskr: this.formBuilder.control(null, Validators.required),
      OffertDat: this.formBuilder.control(null, Validators.required),
      GiltigTomDatum: this.formBuilder.control(null, Validators.required),
      q_jis_reminderdate: this.formBuilder.control(null, Validators.required),
      dateValues: this.formBuilder.control(this.dateValues),
      OrdTyp: this.formBuilder.control(null, Validators.required),
      /*   offstatus: this.formBuilder.control(null, Validators.required), */
      KundRef2: this.formBuilder.control(null, Validators.required),
      KundBestNr: this.formBuilder.control(null, Validators.required),
      email1: this.formBuilder.control(null, Validators.email),
      email2: this.formBuilder.control(null, Validators.email)
    });


    this.form.get('dateValues')?.valueChanges.subscribe(value => {
      const months = Number(value);
      let date = new Date(this.form.get('OffertDat').value);
      date.setMonth(date.getMonth() + months);
      date.setDate(date.getDate() - 1);
      this.handleDateSelected(date, 'GiltigTomDatum');
    });
  }



  public setSelectedCustomerId(value: string[]): void {
    if (value?.length > 0 && value[0]) {
      this.customerId = value[0];
    } else {
      this.customerId = null;
    }
  }

  handleDateSelected(date: Date, field) {
    if (!date) {
      return;
    }
    const dateString = format(date, 'yyyy-MM-dd');
    this.form.get(field).setValue(dateString);
  }

  selectProduct({ item }, index) {
    this.rows[index].productId = item.id;
    this.rows[index].id = item.id;
  }

  addRow() {
    this.productSelect.push(null);
    this.rows.push({
      id: null,
      extra: {
        procargs: {
          OrdStAntal: null
        }
      },
      productId: null,
      quantity: null
    });
  }

  removeRow(index) {
    this.productSelect.splice(index, 1);
    this.rows.splice(index, 1);
  }

  checkRows(): boolean {
    let procargsRowFields = ['q_oo_ofheoq', 'q_oo_ofhprognose', 'vb_pris'];
    if(this.form.get('OrdTyp').value === '973'){
      procargsRowFields = procargsRowFields.filter(item => item !== 'q_oo_ofheoq');
    }
    for (const row of this.rows) {
      row.extra.procargs.OrdStAntal = row.quantity;
      for (const field of procargsRowFields) {
        if (!row.extra?.procargs[field]) {
          return false;
        }
      }
    }
    return true;
  }

 

  save() {
    this.loading = true;
    this.saveClick = true;
    const checkRows = this.checkRows();

    if (this.form.invalid || !checkRows) {
      this.form.markAllAsTouched();
      this.loading = false;
      return;
    }

    let formData = this.form.getRawValue();

    formData.email= formData.email = [formData.email1 || '', formData.email2 || ''].join(';').replace(/(^;|;$)/g, '');;
    delete formData.email1;
    delete formData.email2;
    delete formData.dateValues;

    let payloadBlockOrder: CheckoutObject = {
      rows: this.rows,
      extra: {
        procargs: formData
      },
      targetOrderChannel: 'CREATE_OO_BLOCK_ORDER_AGREEMENT',
      deliveryCustomerId: this.customerId
    };

    const url = 'json/order/create/' + payloadBlockOrder.targetOrderChannel;
    this.orderSubmitService
      .postOrder(url, payloadBlockOrder)
      .pipe(
        first(),
        catchError(error => {
          this.loading = false;

          this.gungModalService.openConfirmYesNoModal(
            'ERROR',
            `${this.translateService.instant('BLOCK_ORDER_CREATION_FAILED')}<br/>${error.error.gungMessage}`,
            { size: 'md' },
            'OK',
            null,
            true
          );
          console.error('Erro:', error);
          return EMPTY;
        })
      )
      .subscribe(data => {
        this.loading = false;

        this.gungModalService
          .openConfirmYesNoModal('SUCCESS', 'BLOCK_ORDER_CREATED', { size: 'sm' }, 'OK', null)
          .then(() => {
            this.router.navigate(['/block-order-agreements']);
          });
      });
  }

  filterOrderType(data){
    const allowTypes = ['970', '971', '972', '973'];
    if(allowTypes.includes(data.key)){
      return true;
    }
    return false;
  }
}
