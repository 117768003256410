<div class="table-responsive gung-responsive w-100">
  <table class="table table-striped-mobile">
    <thead>
      <tr>
        <th></th>
        <th translate>ID</th>
        <th translate>NAME</th>
        <ng-container *ngFor="let headerKey of dynamicColumns">
          <th *ngIf="headerKey.isDisplayList" class="fit-column">{{ headerKey.translationKey | translate }}</th>
        </ng-container>
        <th translate *ngIf="includeAvailability">AVAILABILITY</th>
        <th translate>PRICE</th>
        <th style="min-width: 200px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of mappedData; trackBy: trackByFn">
        <td>
          <div class="table-image">
            <img
            class="img-fluid"
            *ngIf="data.product.extra && data.product.extra.images && data.product.extra.images[0]"
            [src]="data.product.extra.images[0].s3Uri | gungImageUrl
              :'type:thumbnail'
              :'etag:'+data.product.extra.images[0].s3ETag
            "
            alt=""
          />
          <div class="zoomed-image">
            <img
              *ngIf="data.product.extra && data.product.extra.images && data.product.extra.images[0]"
              [src]="data.product.extra.images[0].s3Uri | gungImageUrl
                :'type:thumbnail'
                :'etag:'+data.product.extra.images[0].s3ETag
              "
              alt="Product Image Enlarged"
            />
          </div>
          </div>

        </td>
        <!-- <ng-template libCustomHost></ng-template> -->
        <td [attr.data-label]="'ID' | translate">
          <a [routerLink]="[(data.product?.productType === 'concept' ? '/concept' : '/product/'), data.product.id]" class="text-dark">
            {{ data.product.id }}
          </a>
        </td>
        <td [attr.data-label]="'NAME' | translate">
          <a [routerLink]="[(data.product?.productType === 'concept' ? '/concept' : '/product/'), data.product.id]" class="text-dark">
            {{ data.product.name }}
          </a>
        </td>
        <td
          [attr.data-label]="dynamicColumns?.[i]?.translationKey | translate"
          *ngFor="let value of data.product.dynamicCollumnsValues; let i = index"
          class="text-right text-nowrap"
        >
          <span>{{ value }}</span>
        </td>
        <td [attr.data-label]="'AVAILABILITY' | translate" *ngIf="includeAvailability">
          @if(data.product?.productType !== 'concept') {
            <lib-availability [availability]="data.availability" [isSales]="isSales" [product]="data.product"></lib-availability>
          }
        </td>
        <td [attr.data-label]="'PRICE' | translate">
          <span *ngIf="data.price">
            <span>
              <lib-price [price]="data.price" type="NET" [currency]="data.price.customerNetPrice.currencyCode" [product]="data.product"></lib-price>
            </span>
            @if(data.price.customerDiscountPercent && data.price.customerDiscountPercent !== 0){
              <span>(-{{ data.price.customerDiscountPercent }}%)</span>
            }
          </span>
        </td>
        <td [attr.data-label]="'BUY' | translate">
          <container-element [ngSwitch]="data.product?.productType">
            <button *ngSwitchCase="'concept'" class="btn btn-sm btn-primary w-100" [routerLink]="['/concept', data.product.id]" translate>SHOW_PRODUCTS</button>
            <lib-buy-button-wrapper *ngSwitchDefault [product]="data.product" [availability]="data.availability"></lib-buy-button-wrapper>
          </container-element>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="findError" class="mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
<div *ngIf="findError" class="col-12 mb-2 text-center">
  {{ errorMessage }}
</div>
