import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ListLayout, ListLayoutSingleComponent, SimpleConfigBaseFilter } from 'gung-list';
import { Order } from 'gung-standard';
import { JeevesOrderListConfigService, OrderDateFilter, OrderExpectedDeliveryFilter, OrderStatusFilter } from 'gung-standard-jeeves';
import { Observable, mergeMap, of } from 'rxjs';
import { OttoOlsenDeliveryOverviewCardListComponent } from '../components/otto-olsen-delivery-overview-card-list/otto-olsen-delivery-overview-card-list.component';

@Injectable({
  providedIn: 'root'
})
export class OttoOlsenDeliveryOverviewListConfigService extends JeevesOrderListConfigService {
  includeRows = true;

  getItems(): Observable<Order[]> {
    if (this.customerId == null) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        mergeMap(customer => {
          if (customer != null) {
            return this.orderService.getOrders(customer.id, false, this.maxSize, this.includeRows, 365);
          } else {
            return of([]);
          }
        })
      );
    } else {
      return this.orderService.getOrders(this.customerId, false, this.maxSize, this.includeRows, 365);
    }
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [
      new OrderDateFilter(),
      new OrderExpectedDeliveryFilter(),
      new OrderStatusFilter(this.metadataService),
      new deliveryStatusFilter(),
    ];
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OttoOlsenDeliveryOverviewCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: Order): string[] {
    return [item.id, item.extra.oh.kundref2, item.extra.oh.kundbestnr, ...(item.rows?.map(row => row.extra.orp?.orpradtext || '') || [])];
  }
}

export class deliveryStatusFilter extends SimpleConfigBaseFilter<Order> {
  getOptionIds(item: Order): string[] {
    if (item.rows?.length > 0) {
      if (item.rows.findIndex(row => row.extra.orp.ordlevantal > 0) === -1) {
        return ['NOT_DELIVERED'];
      }
      if (item.rows.findIndex(row => row.extra.orp.ordrestant > 0) === -1) {
        return ['DELIVERED'];
      }
      return ['PARTIALLY_DELIVERED'];
    }
    return [item?.extra.gme?.status];
  }

  getOptionName(key: string): string {
    return key;
  }

  getName(): string {
    return 'DELIVERY_STATUS';
  }
}
