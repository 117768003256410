import { Component, OnInit } from '@angular/core';
import { ImportLinesheetComponent } from 'gung-standard';
import { map } from 'rxjs';

@Component({
  selector: 'otto-olsen-import-linesheet',
  templateUrl: './otto-olsen-import-linesheet.component.html',
  styleUrl: './otto-olsen-import-linesheet.component.scss'
})
export class OttoOlsenImportLinesheetComponent extends ImportLinesheetComponent implements OnInit {
  ngOnInit(): void {
    super.ngOnInit();
    this.selectFormat(this.COLUMN_FORMAT);
  }

  uploadFileColumn(files: File[]) {
    this.loading = true;
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }
    this.linesheetService.cartsMatchProducts(this.selectedAssortment.id, this.selectedFlow.id, formData).pipe(
      map(res => {
        const result = structuredClone(res);
        for (let index = res.mapped.length - 1; index >= 0; index--) {
          const element = res.mapped[index];
          if (String(element.product.extra.ar.itemtypecd1) === '0' || String(element.product.extra.ar.itemtypecd1) === '7') {
            result.mapped.splice(index, 1);
            result.errors.push(`Product found for row #${element.rowCount} with id ${element.productId} but is blocked`);
          }
        }
        return result;
      }),
    ).subscribe(this.getImportResultHandler());
  }
}
