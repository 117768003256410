@for (ordersType of orders; track ordersType.id) {
  <h5>{{ ordersType.name || '-' | translate }}</h5>
  <div class="table-responsive gung-responsive">
    <table class="table">
      <thead>
        <tr>
          <th translate>BLOCK_ORDER_AGREEMENT_ID</th>
          @if (!customerDetails) {
            <th translate>CUSTOMER</th>
          } @else {
            <th translate>BLOCK_ORDER_CONTRACT</th>
            <th translate>BLOCK_ORDER_STATUS</th>
            <th translate>BLOCK_ORDER_CONTACT</th>
            <th translate>BLOCK_ORDER_REF_NUMBER</th>
          }
          @if (!customerDetails) {
            <th translate>BLOCK_ORDER_AGREEMENT_DATE</th>
          }
          <th translate>BLOCK_ORDER_AGREEMENT_VALID_TO_DATE</th>
         <th></th> 
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let group of ordersType.groupedOrder">
          <tr>
            <td [attr.data-label]="'BLOCK_ORDER_AGREEMENT_ID' | translate">
              <button
                type="button"
                class="btn btn-link"
                (click)="
                  icon.classList.toggle('fa-arrow-turn-up');
                  icon.classList.toggle('fa-arrow-turn-down');
                  toggleLines(group)
                "
              >
                <i #icon class="fa-solid fa-arrow-turn-up fa-rotate-180"></i>
              </button>
              <span class="text-nowrap">
                {{ group.agreementId }}
              </span>
            </td>
            @if (!customerDetails) {
              <td [attr.data-label]="'CUSTOMER' | translate">
                <span class="text-nowrap">
                  <b>{{ group.customerId }}</b> - {{ group.customerName }}
                </span>
              </td>
            } @else {
              <td [attr.data-label]="'BLOCK_ORDER_AGREEMENT_DATE' | translate">
                <span>{{ group.offbeskr }}</span>
              </td>
              <td [attr.data-label]="'BLOCK_ORDER_AGREEMENT_DATE' | translate">
                <span>{{ group.status }}</span>
              </td>
              <td [attr.data-label]="'BLOCK_ORDER_AGREEMENT_DATE' | translate">
                <span>{{ group.contact }}</span>
              </td>
              <td [attr.data-label]="'BLOCK_ORDER_AGREEMENT_DATE' | translate">
                <span>{{ group.refNumber }}</span>
              </td>
            }
            @if (!customerDetails) {
              <td [attr.data-label]="'BLOCK_ORDER_AGREEMENT_DATE' | translate">
                <span>{{ group.agreementDate | date: 'yyyy-MM-dd' }}</span>
              </td>
            }
            <td [attr.data-label]="'BLOCK_ORDER_AGREEMENT_VALID_TO_DATE' | translate">
              <span>{{ group.agreementValidToDate | date: 'yyyy-MM-dd' }}</span>
            </td>
            <td class="text-right">
              <gung-button
                classes="btn-secondary btn ml-1"
                icon="fa-regular fa-file-pdf"
                [onClick]="download.bind(this, group.agreementId)"
                label="{{ 'DOWNLOAD' | translate }}"
              />

              <gung-button
                classes="btn-secondary btn ml-1"
                icon="fa-regular fa-upload"
                [onClick]="upload.bind(this, group.agreementId)"
                label="{{ 'UPLOAD' | translate }}"
              />
            </td>
          </tr>
          <tr #productsRow *ngIf="showGroup[group.agreementId]">
            <td colspan="10" class="p-0">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">
                      <span translate>OO_BOA_VARENUMMER</span>
                    </th>
                    <th scope="col">
                      <span translate>OO_BOA_BESKRIVELSE</span>
                    </th>
                    <th scope="col">
                      <span translate>OO_BOA_LAGERSTATUS</span>
                    </th>
                    <th scope="col">
                      <span translate>PRICE</span>
                    </th>
                    <th scope="col">
                      <span translate>UNIT</span>
                    </th>
                    <th scope="col">
                      <span translate>OO_BOA_PROGNOSE</span>
                    </th>

                    <th scope="col">
                      <span translate>OO_BOA_ANTALL</span>
                    </th>

                    <th scope="col">
                      <span translate>OO_BOA_AVTALEVERDI</span>
                    </th>
                    <th scope="col" style="width: 120px">
                      <span translate></span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let row of group.rows; let idx = index; trackBy: trackByFn"
                    [ngClass]="{ 'line-checked': !!row.checked }"
                  >
                    <td [attr.data-label]="'PRODUCT_ID' | translate">
                      <span class="text-nowrap">
                        <small class="text-muted">{{ row.extra.q_gung_ofh?.artnr }}</small> <br />
                      </span>
                      <small class="text-muted"
                        ><b
                          *ngIf="
                            row.extra.q_gung_ofh?.ftgnr &&
                            row.extra._product?.extra._customerCrossReferences &&
                            row.extra._product.extra._customerCrossReferences[row.extra.q_gung_ofh.ftgnr]
                          "
                          class="d-block small"
                        >
                          {{ row.extra._product.extra._customerCrossReferences[row.extra.q_gung_ofh.ftgnr] }}
                        </b></small
                      >
                    </td>

                    <td [attr.data-label]="'OO_BOA_BESKRIVELSE' | translate">
                      <span class="text-nowrap">
                        <small class="text-muted">{{ row.extra.q_gung_ofh?.ar_artbeskr }}</small> <br />
                        <small class="text-muted"><b>{{ row.extra._product?.extra?.ar?.artbeskrspec }}</b></small> <br />
                      </span>
                    </td>

                    <td [attr.data-label]="'OO_BOA_LAGERSTATUS' | translate">
                      @if (row.extra._av) {
                        <lib-availability [availability]="row.extra._av" [isSales]="isSales"></lib-availability>
                      } @else {
                        <div class="spinner-border" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      }
                    </td>

                    <td [attr.data-label]="'PRICE' | translate">
                      <span class="text-nowrap">
                        <small class="text-muted">
                          {{ getPriceWithDiscount(row) | currency: row.extra.q_gung_ofh?.valkod }} <br />
                          @if (row.extra.q_gung_ofh?.rabatt1 != 0.0) {
                            <span>{{ 'PRICE' | translate }}</span
                            >: {{ row.extra.q_gung_ofh?.vb_pris }} ({{ row.extra.q_gung_ofh?.rabatt1 }}%
                            <span>{{ 'DISCOUNT' | translate }}</span
                            >)
                          } @else {
                            (<span>{{ 'NET_PRICE' | translate }}</span
                            >)
                          }
                        </small>
                      </span>
                    </td>

                    <td [attr.data-label]="'UNIT' | translate">
                      @if (row.extra._product) {
                        <span class="text-nowrap">
                          <small class="text-muted">{{ row.extra._product?.extra.ar?.enhetskod }}</small>
                        </span>
                      }
                    </td>

                    <td [attr.data-label]="'OO_BOA_PROGNOSE' | translate">
                      <span class="text-nowrap">
                        <small class="text-muted">{{ row.extra.q_gung_ofh?.q_oo_ofhprognose }}</small>
                      </span>
                    </td>

                    <td [attr.data-label]="'OO_BOA_ANTALL' | translate">
                      <span class="text-nowrap">
                        <small class="text-muted">{{ row.extra.q_gung_ofh?.ordantal }}</small>
                      </span>
                    </td>

                    <td [attr.data-label]="'OO_BOA_AVTALEVERDI' | translate">
                      <span class="text-nowrap">
                        <small class="text-muted">{{
                          row.extra.q_gung_ofh?.vboffradsum | currency: row.extra.q_gung_ofh?.valkod
                        }}</small>
                      </span>
                    </td>

                    <td>
                      @if (row.extra._product) {
                        <otto-olsen-buy-btn
                          name="buy-btn-package-size"
                          class="height-30 ml-auto"
                          style="width: 150px"
                          [id]="row.extra._product?.id"
                          [minimumOrderQuantity]="
                            row.extra._product?.firstStepAmount || row.extra._product?.packageSize
                          "
                          [stepAmount]="row.extra._product?.packageSize"
                          [decimals]="row.extra._product?.extra.ar.antdec"
                          [originalSize]="row.extra._product?.originalSize"
                          [unit]="row.extra._product?.extra.ar?.enhetskod"
                          [activeInB2B]="row.extra._product?.extra?.pim?.activeInB2B"
                          [product]="row.extra._product"
                          [availability]="row.extra._av"
                        ></otto-olsen-buy-btn>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
}
