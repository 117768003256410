import { Component } from '@angular/core';
import { UserDetailsCustomersTableComponent } from 'gung-standard';

@Component({
  selector: 'otto-olsen-user-details-customers-table',
  templateUrl: './otto-olsen-user-details-customers-table.component.html',
  styleUrl: './otto-olsen-user-details-customers-table.component.scss'
})
export class OttoOlsenUserDetailsCustomersTableComponent extends UserDetailsCustomersTableComponent {

}
