<div *ngIf="!!customer">
  <div class="row mb-4">
    <div class="col d-flex flex-direction-row justify-content-between">
      <h3>{{ customer.name }}</h3>
      <lib-action-btn [item]="customer" [config]="configService"></lib-action-btn>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <lib-gung-tabset
        *ngIf="!!customer"
        justify="fill"
        #ngbTab="gungTabset"
        (tabChange)="tabChanged($event)"
        [activeId]="activeIdString"
      >
        <lib-gung-tab [title]="'ADDRESS' | translate" id="lib-gung-tab-0">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col-12 col-md-6">
                <!-- <div class="form-group">
                                    <label class="control-label" for="nameInput" translate>CUSTOMER_NAME</label>
                                    <input class="form-control" name="nameInput" type="text" [(ngModel)]="customer.name"
                                        disabled />
                                </div> -->
                <h3 translate>POST_ADDRESS</h3>

                <div class="form-group">
                  <label class="control-label" for="address" translate>FR_FTGPOSTADR1</label>
                  <input
                    class="form-control"
                    name="address"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostadr1"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="postalCode" translate>FR_FTGPOSTNR</label>
                  <input
                    class="form-control"
                    name="postalCode"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostnr"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostadr3"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <h3 translate>DELIVERY_ADDRESS</h3>

                <div class="form-group">
                  <label class="control-label" for="ftgpostadr5" translate>FR_FTGPOSTADR1</label>
                  <input
                    class="form-control"
                    name="ftgpostadr5"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostadr5"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="postalCode" translate>FR_FTGPOSTNR</label>
                  <input
                    class="form-control"
                    name="postalCode"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftglevpostnr"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="city" translate>FR_FTGPOSTADR3</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra.fr.ftgpostlevadr3"
                    disabled
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'DELIVERY_ADDRESS_TAB' | translate" id="lib-gung-tab-1">
          <ng-template libGungTabContent>
            <div class="pt-3">
              <lib-filter-list [configService]="jeevesCustomerDeliveryLocationListConfigService"> </lib-filter-list>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'BLOCK_ORDER_AGREEMENTS' | translate" id="block-order">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <otto-block-order-agreements [customerId]="customer.id"/>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'BILLING_INFORMATION' | translate" id="lib-gung-tab-2">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="city" translate>NAME</label>
                  <input
                    class="form-control"
                    name="city"
                    type="text"
                    [(ngModel)]="customer.extra._billing.ftgnamn"
                    disabled
                  />
                </div>
                <div class="form-group">
                  <label class="control-label" for="invoiceEmail" translate>INVOICE_EMAIL</label>
                  <input
                    class="form-control"
                    name="invoiceEmail"
                    type="text"
                    [(ngModel)]="customer.extra.invoiceEmail"
                    disabled
                  />
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="control-label" for="orgnr" translate>FR_ORGNR</label>
                  <input
                    class="form-control"
                    name="orgnr"
                    type="text"
                    [(ngModel)]="customer.extra._billing.orgnr"
                    disabled
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'USERS' | translate" id="lib-gung-tab-3">
          <ng-template libGungTabContent>
            <div class="row pt-4 users-style">
              <div class="col">
                <lib-customer-user-list></lib-customer-user-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'ORDERS' | translate" id="lib-gung-tab-4">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-order-list></lib-order-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'INVOICES' | translate" id="lib-gung-tab-5">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <otto-olsen-invoice-list></otto-olsen-invoice-list>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'ORDER_ANALYTICS_BETA' | translate" *ngIf="isSalesOrAdmin" id="lib-gung-tab-7">
          <ng-template libGungTabContent>
            <div class="row pt-4">
              <div class="col">
                <lib-customer-order-analytics></lib-customer-order-analytics>
              </div>
            </div>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>
</div>
