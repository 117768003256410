<div class="card mb-2">
  <div class="card-body">
    <div class="card-title row">
      <div class="col">
        <a [routerLink]="'/orders/' + data.id">
          <i class="fas fa-eye pr-1" style="font-size: larger"></i>
          <h5>{{ data.id }}</h5>
        </a>
      </div>
      <div class="col-auto ml-auto">
        <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-3">
        <div class="pb-1">
          <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="data.extra.oh.orddatum"></lib-gung-date-output>
        </div>
        <div class="pb-1">
          <lib-gung-date-output [gungTranslate]="'EXPECTED_DELIVERY_DATE'" [value]="data.extra.oh.ordberlevdat">
          </lib-gung-date-output>
        </div>
      </div>
      <div class="col-xs-12 col-md-3">
        <div class="pb-1">
          <lib-gung-meta-output
            [value]="data.extra.oh.ordstat"
            [gungTranslate]="'ORDER_STATUS'"
            metadataTable="x7"
            metaOptionName="ordstatbeskr"
          ></lib-gung-meta-output>
        </div>
        <div class="pb-1">
          <div class="form-group">
            <label class="mb-0 font-weight-bold" translate>TOTAL</label>
            <lib-price
              [price]="data.extra.oh.ohvbordsum"
              [currency]="data.extra.oh.valkod"
              class="form-control-plaintext p-0"
            ></lib-price>
          </div>
        </div>
      </div>
      <div class="col col-xs-6 col-md-3">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'NAME'"
            [renderStatic]="true"
            [ngModel]="
              data.extra.oh.ordlevadr1 ||
              (data.extra.deliveryCustomer ? data.extra.deliveryCustomer.name : data.extra.customerName)
            "
          >
          </lib-gung-text-input>
          <div class="pb-1">
            <lib-gung-meta-output
              [value]="data.extra.oh.ordlevadrlandskod"
              [gungTranslate]="'COUNTRY'"
              metadataTable="xr"
              metaOptionName="land"
            ></lib-gung-meta-output>
          </div>
        </div>
      </div>
      <div class="col col-xs-6 col-md-3">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'YOUR_REFERENCE'"
            [renderStatic]="true"
            [ngModel]="data.extra.oh.kundref2"
          >
          </lib-gung-text-input>
          <div class="pb-1">
            <lib-gung-text-input
              [gungTranslate]="'PO_NUMBER'"
              [renderStatic]="true"
              [ngModel]="data.extra.oh.kundbestnr"
            >
            </lib-gung-text-input>
          </div>
        </div>
      </div>
    </div>
    @if (data.rows?.length > 0) {
      <div class="row">
        <div class="col-12 table-responsive gung-responsive">
          <table class="table table-striped border">
            <thead>
              <tr>
                <th>{{'PRODUCT_ID' | translate}}</th>
                <th>{{'NAME' | translate}}</th>
                <th>{{'MARKING' | translate}}</th>
                <th>{{'ESTIMATED_DELIVERY_DATE' | translate}}</th>
                <th>{{'QUANTITY' | translate}}</th>
                <th>{{'QUANTITY_DELIVERED' | translate}}</th>
                <th>{{'QUANTITY_BACKORDER' | translate}}</th>
                <th>{{'UNIT_PRICE' | translate}}</th>
                <th>{{'DISCOUNT' | translate}}</th>
                <th>{{'TOTAL_PRICE' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of data.rows" [ngClass]="row.extra.orp.ordradst === 90 ? 'deleted' : ''">
                <td [attr.data-label]="'PRODUCT_ID' | translate">
                  <a [routerLink]="row.extra._path ? row.extra._path : ['/product/', row.productId]">{{ row.productId }}</a>
                </td>
                <td [attr.data-label]="'NAME' | translate">
                  <a [routerLink]="row.extra._path ? row.extra._path : ['/product/', row.productId]">
                    <span *ngIf="row.extra.orp.artbeskr">
                      {{ row.extra.orp.artbeskr }}
                    </span>
                    <span *ngIf="this.products && this.products[row.productId] && !row.extra.orp.artbeskr">
                      {{ this.products[row.productId].name }}
                    </span>
                  </a>
                </td>
                <td [attr.data-label]="'MARKING' | translate">
                  {{ row.extra.orp.orpradtext }}
                </td>
                <td [attr.data-label]="'ESTIMATED_DELIVERY_DATE' | translate">
                  {{ row.extra.orp.ordberlevdat | date: dateUtilService.dateFormat }}
                </td>
                <td [attr.data-label]="'QUANTITY' | translate">
                  {{ row.quantity }}
                </td>
                <td [attr.data-label]="'QUANTITY_DELIVERED' | translate">
                  {{ row.extra.orp.ordlevantal }}
                </td>
                <td [attr.data-label]="'QUANTITY_BACKORDER' | translate">
                  {{ row.extra.orp.ordrestant }}
                </td>
                <td [attr.data-label]="'UNIT_PRICE' | translate">
                  <lib-price [price]="row.extra.orp.vb_pris" [currency]="data.extra.oh.valkod"></lib-price>
                </td>
                <td [attr.data-label]="'DISCOUNT' | translate">
                  <span>{{ (1 - row.extra.orp.vb_nettopris / row.extra.orp.vb_pris) * 100 | number: '1.0-2' }}%</span>
                  <!-- Show separate discounts if non zero -->
                  <!-- <span *ngIf="row.extra.orp.rabatt2"><br/>{{ row.extra.orp.rabatt2 | number: '1.0-2' }}%</span>
                  <span *ngIf="row.extra.orp.rabatt3"><br/>{{ row.extra.orp.rabatt3 | number: '1.0-2' }}%</span>
                  <span *ngIf="row.extra.orp.kundrabatt"><br/>{{ row.extra.orp.kundrabatt | number: '1.0-2' }}%</span> -->
                </td>
                <td [attr.data-label]="'TOTAL_PRICE' | translate">
                  <lib-price [price]="row.extra.orp.vbordradsum" [currency]="data.extra.oh.valkod"></lib-price>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    }
  </div>
</div>
